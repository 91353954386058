import { useState } from 'react';
import { useFormikContext } from 'formik';
import {
    AdvertiserAccount,
    AdvertiserFormModel,
    GcmAdvertiser,
    GcmParentAccount,
} from 'platform/advertisers/advertiser.types';
import {
    fetchFloodlightActivities,
    fetchGcmAdvertisers,
    fetchGcmParentAccounts,
} from 'platform/advertisers/services/advertiser.service';
import { SelectItem } from 'platform/common/common.types';
import FormRow from 'platform/common/components/FormRow/FormRow';
import InfoIconTooltip from 'platform/common/components/InfoIconTooltip/InfoIconTooltip';
import Separator from 'platform/common/components/Separator/Separator';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required } from 'platform/common/utils/validators.util';
import FormCreatableSelect from 'platform/formik/FormCreatableSelect/FormCreatableSelect';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { ADVERTISER_INTEGRATION_HELP_KEY } from './AdvertiserIntegration';

interface Props {
    field: string;
    account: AdvertiserAccount | undefined;
    helpKey?: string;
}

const GcmAccountFormBlock = ({ field, account, helpKey }: Props) => {
    const { setFieldValue } = useFormikContext<AdvertiserFormModel>();
    const [customFloodlightPageviewId, setCustomFloodlightPageviewId] = useState<number | undefined>();

    const selectedAdvertiserId = account?.externalId;
    const selectedParentAccountId = account?.externalParentAccountId;
    const selectedPageviewId = account?.floodlightPageviewId;
    const useCustomFloodlightConfigurationId = account?.useCustomFloodlightConfigurationId;

    const [
        {
            data: [gcmParentAccounts, gcmAdvertisers],
            loading: loadingAccounts,
        },
    ] = usePromise<[GcmParentAccount[], GcmAdvertiser[]]>(
        [[], []],
        () => Promise.all([fetchGcmParentAccounts(), fetchGcmAdvertisers()]),
        []
    );

    const [{ data: activityOptions, loading: loadingActivities }] = usePromise<SelectItem<number>[]>(
        [],
        async () => {
            if (!selectedAdvertiserId) return [];
            const activities = await fetchFloodlightActivities(selectedAdvertiserId);
            if (!activities.some((a) => a.id === selectedPageviewId)) {
                setCustomFloodlightPageviewId(selectedPageviewId);
            }
            return activities.map((a) => ({ label: a.name, value: a.id }));
        },
        [selectedAdvertiserId, selectedPageviewId]
    );

    return (
        <>
            <FormRow
                label="External account"
                helpKey={`${helpKey}.${ADVERTISER_INTEGRATION_HELP_KEY}_account`}
            >
                <FormSelect
                    name={`${field}.externalParentAccountId`}
                    onChange={() => {
                        setFieldValue(`${field}.externalId`, undefined);
                        setFieldValue(`${field}.floodlightPageviewId`, undefined);
                        setFieldValue(`${field}.floodlightConfigurationId`, undefined);
                    }}
                    options={gcmParentAccounts}
                    isLoading={loadingAccounts}
                    getOptionValue={(a: GcmParentAccount) => String(a.id)}
                    getOptionLabel={(a: GcmParentAccount) => a.name}
                    className="AdvertiserIntegrations-longField"
                    validate={required}
                />
            </FormRow>
            <FormRow
                label="Advertiser ID"
                childrenColClassName="d-flex align-items-center"
                helpKey={`${helpKey}.${ADVERTISER_INTEGRATION_HELP_KEY}_externalId`}
            >
                <FormSelect
                    name={`${field}.externalId`}
                    onChange={(value) => {
                        setFieldValue(`${field}.floodlightPageviewId`, undefined);
                        setFieldValue(
                            `${field}.floodlightConfigurationId`,
                            gcmAdvertisers.find(({ id }) => String(id) === value)?.floodlightConfigurationId
                        );
                    }}
                    options={gcmAdvertisers.filter((a) => String(a.account.id) === selectedParentAccountId)}
                    isLoading={loadingAccounts}
                    getOptionValue={(a: GcmAdvertiser) => String(a.id)}
                    getOptionLabel={(a: GcmAdvertiser) => a.name}
                    className="AdvertiserIntegrations-longField"
                    validate={required}
                />
                <InfoIconTooltip className="ms-2">
                    While most advertiser accounts run on CM360, some smaller ones may only have vendor
                    tracking. In the latter case, you may switch off this whole CM360 block.
                </InfoIconTooltip>
            </FormRow>
            <Separator label="Optional" className="mb-0 pb-0" />
            <FormRow
                label="Use custom Floodlight configuration ID"
                helpKey={`${helpKey}.${ADVERTISER_INTEGRATION_HELP_KEY}_useCustomFloodlightConfigurationId`}
            >
                <div className="AdvertiserIntegrations-longField d-flex align-items-center">
                    <FormSwitch
                        name={`${field}.useCustomFloodlightConfigurationId`}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setFieldValue(`${field}.floodlightConfigurationId`, null);
                            }
                        }}
                    />
                    <InfoIconTooltip className="ms-2">
                        By default, CM360 Advertiser ID is used as Floodlight configuration ID. But you can
                        customise it for cases when, for example, multiple advertiser accounts share the same
                        Floodlight configuration.
                    </InfoIconTooltip>
                </div>
            </FormRow>
            {useCustomFloodlightConfigurationId && (
                <FormRow helpKey={`${helpKey}.${ADVERTISER_INTEGRATION_HELP_KEY}_floodlightConfigurationId`}>
                    <FormInput
                        className="AdvertiserIntegrations-longField "
                        name={`${field}.floodlightConfigurationId`}
                        validate={required}
                    />
                </FormRow>
            )}
            <FormRow
                label="Floodlight pageview ID"
                helpKey={`${helpKey}.${ADVERTISER_INTEGRATION_HELP_KEY}_floodlightPageviewId`}
            >
                <FormCreatableSelect
                    isClearable
                    name={`${field}.floodlightPageviewId`}
                    className="AdvertiserIntegrations-longField"
                    options={
                        customFloodlightPageviewId
                            ? [
                                  ...activityOptions,
                                  { label: customFloodlightPageviewId, value: customFloodlightPageviewId },
                              ]
                            : activityOptions
                    }
                    isLoading={loadingActivities}
                    onNewOption={setCustomFloodlightPageviewId}
                    formatCreateLabel={(value) => (
                        <span>
                            <b>Use custom id</b>
                            {` "${value}"`}
                        </span>
                    )}
                />
            </FormRow>
        </>
    );
};

export default GcmAccountFormBlock;
