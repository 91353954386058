import { useState } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import * as CSV from 'csv-string';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { ReportFilter } from 'platform/analytics/analytics.types';
import {
    fetchProducts,
    getProductCategories,
} from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import {
    Product,
    ProductCategory,
} from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.types';
import { CampaignPatch } from 'platform/campaign/campaign/campaign.types';
import CampaignStateIndicator from 'platform/campaign/campaign/components/CampaignStateIndicator';
import { CampaignState } from 'platform/campaign/campaign/constants/state.constant';
import { bulkUpdateCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { sendChatMessages } from 'platform/chat/chat.service';
import { ChatMessage } from 'platform/chat/chat.types';
import { SelectOption, TableCell, Period } from 'platform/common/common.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditSelect from 'platform/common/components/InlineEditSelect/InlineEditSelect';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { arrayItemUpdate } from 'platform/common/utils/array.util';

interface Props {
    advertiserId: number;
    toggle: () => void;
    afterUpdate: () => void;
    filters: ReportFilter[];
    date: Period;
}

interface DataRow {
    advertiser_id?: number;
    advertiser_name?: string;
    campaign_id?: number;
    campaign_name?: string;
    campaign_state?: CampaignState;
    channel?: string;
    vendor_name?: string;
    product_category?: string | undefined;
    product_label?: string;
    product_sub_label?: string;
    search_term_strategy?: string;
}

type ProductSubLabel = string | null;

interface ProductObject {
    label: string;
    subLabels: ProductSubLabel[];
}

interface CategoryObject {
    category: string | null;
    products: ProductObject[];
}

interface ProductLabelOption extends SelectOption {
    category?: string;
}

const productsByCategoryArray = (
    productCategories: ProductCategory[] | undefined,
    productLabels: Product[]
) => {
    let result: CategoryObject[] = [];

    if (productCategories?.length) {
        result = productCategories.map((category) => {
            const arrangedProducts = productLabels
                .filter((advertiser) => advertiser.category === category.name)
                .map((advertiser) => ({
                    label: advertiser.name,
                    subLabels: advertiser.subLabels.map((item) => item?.name),
                }));
            return { category: category.name, products: arrangedProducts };
        });
    }

    if (productLabels?.some((item) => !item?.category)) {
        result.push({
            category: null,
            products: productLabels.map((advertiser) => ({
                label: advertiser.name,
                subLabels: advertiser.subLabels.map((item) => item?.name),
            })),
        });
    }
    return result;
};

const getProductSubLabelOptions = (arrangedProductLabels: CategoryObject[], productLabel?: string) => {
    if (!productLabel) return [];

    const selectedCategory = arrangedProductLabels.find((category) =>
        category.products?.some((product) => product.label === productLabel)
    );

    if (selectedCategory) {
        const subLabelOptions = selectedCategory.products
            .filter((product) => product.label === productLabel)
            .flatMap((product) =>
                product.subLabels
                    .filter((subLabel) => subLabel !== null)
                    .map((subLabel) => ({
                        label: subLabel!,
                        value: subLabel!,
                    }))
            );

        return subLabelOptions;
    }

    return [];
};

const mapDataForRequest = (data: DataRow[]) =>
    data.map(
        ({
            advertiser_name,
            campaign_id,
            campaign_name,
            vendor_name,
            search_term_strategy,
            product_category,
            product_label,
            product_sub_label,
        }) => ({
            advertiser_name,
            campaign_id,
            campaign_name,
            vendor_name,
            search_term_strategy,
            product_category,
            product_label,
            product_sub_label,
        })
    );

const BulkCampaignProductSuggestModal = ({ advertiserId, toggle, afterUpdate, filters, date }: Props) => {
    const [updatedData, setUpdatedData] = useState<DataRow[]>([]);

    const [arrangedProductLabels, setArrangedProductData] = useState<CategoryObject[]>([]);
    const [productLabelOptions, setProductLabelOptions] = useState<ProductLabelOption[]>([]);
    const [loading, setLoading] = useState(true);

    const [{ error }] = usePromise(
        [],
        () =>
            generateOlapReport({
                templateId: 'all_columns',
                dimensions: [
                    'advertiser_id',
                    'advertiser_name',
                    'campaign_id',
                    'campaign_name',
                    'campaign_state',
                    'channel',
                    'vendor_name',
                    'search_term_strategy',
                    'product_category',
                    'product_label',
                    'product_sub_label',
                ],
                metrics: [],
                from: date.from,
                to: date.to,
                dimensionFilters: [...filters, { key: 'full_set', values: [true] }],
            }).then(async (reportData) => {
                const existingProducts = await fetchProducts(advertiserId).then((productData) => {
                    const products = productData.map((product) => ({
                        ...product,
                        subLabels: product.subLabels,
                    }));

                    const productByCategory = productsByCategoryArray(
                        getProductCategories(products),
                        productData
                    );
                    setArrangedProductData(productByCategory);

                    setProductLabelOptions(
                        products?.map((product) => ({
                            label: product.name,
                            value: product.name,
                            category: product.category,
                        })) ?? []
                    );
                    return productByCategory;
                });

                const dataWithMissingContent = reportData?.rows?.filter((item) => !item.product_label) ?? [];

                const uniqueLabels = new Set<string>();
                const uniqueItems: DataRow[] = [];

                (reportData?.rows ?? [])
                    .filter((item) => item.product_label)
                    .forEach((item) => {
                        if (uniqueLabels.size < 1 && !uniqueLabels.has(item.product_label)) {
                            uniqueLabels.add(item.product_label);
                            uniqueItems.push(item);
                        }
                    });

                if (uniqueItems.length < 5) {
                    const additionalItems = (reportData?.rows ?? [])
                        .filter(
                            (item) =>
                                item.product_label &&
                                !uniqueItems.some(
                                    (existingItem) => existingItem.campaign_id === item.campaign_id
                                )
                        )
                        .slice(0, 5 - uniqueItems.length);

                    uniqueItems.push(...additionalItems);
                }
                generateSuggestedData(uniqueItems, dataWithMissingContent, existingProducts);
            }),
        []
    );

    const generateSuggestedData = async (
        dataWithContent: DataRow[],
        dataWithMissingContent: DataRow[],
        existing_product: CategoryObject[]
    ) => {
        if (dataWithMissingContent.length) {
            const batches = [];
            for (let i = 0; i < dataWithMissingContent.length; i += 8) {
                batches.push(dataWithMissingContent.slice(i, i + 8));
            }

            const previousMessages: ChatMessage[] = [];
            const responses: any[] = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const batch of batches) {
                const call: ChatMessage = {
                    role: 'user',
                    contentParts: [
                        {
                            type: 'TEXT',
                            content: JSON.stringify({
                                data: CSV.stringify(
                                    mapDataForRequest(batch).concat(mapDataForRequest(dataWithContent))
                                ),
                                existing_product,
                            }),
                        },
                    ],
                };
                // eslint-disable-next-line no-await-in-loop
                const res = await sendChatMessages({
                    agentId: 17,
                    messages: [...previousMessages, call],
                    placeholders: {},
                });

                if (res && res.message) {
                    responses.push(CSV.parse(res?.message, { output: 'objects' }));
                    previousMessages.push(call, {
                        role: 'assistant',
                        contentParts: [{ type: 'TEXT', content: res.message }],
                    });
                }
            }

            const generatedData = dataWithMissingContent?.map((item) => {
                const suggested = responses
                    ?.flatMap((obj) => Object.values(obj))
                    ?.find(
                        (cc: { campaign_id: string }) => Number(cc.campaign_id) === Number(item.campaign_id)
                    );
                if (suggested && typeof suggested === 'object') {
                    return { ...item, ...suggested };
                }
                return item;
            });

            setUpdatedData(generatedData);
        }
        setLoading(false);
    };

    const onApply = async () => {
        if (updatedData.length) {
            await bulkUpdateCampaigns({
                campaigns: updatedData?.map((item) => ({
                    id: item.campaign_id,
                    productLabel: item?.product_label,
                    productSubLabel: item?.product_sub_label,
                })) as CampaignPatch[],
            });
            afterUpdate();
            toggle();
        }
    };

    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '95vw' }}>
            <ModalHeader onClose={toggle}>
                <div>
                    <span className="ModalHeader-title">Suggest Classification</span>
                </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '80vh', minHeight: 200, overflow: 'auto' }}>
                <FormattedTable
                    NoDataComponent={error ? () => <ErrorMessage error={error} /> : undefined}
                    data={updatedData || []}
                    loading={loading}
                    stickyHeader={false}
                    showPagination={false}
                    containerClass="shadow-none"
                    columns={[
                        {
                            Header: 'Channel',
                            width: 150,
                            Cell: (props: TableCell<DataRow>) => <Label>{props.original.channel}</Label>,
                        },
                        {
                            Header: 'Vendor',
                            width: 150,
                            Cell: (props: TableCell<DataRow>) => <Label>{props.original.vendor_name}</Label>,
                        },
                        {
                            Header: 'Campaign name',
                            autoWidth: true,
                            accessor: (row: DataRow) => row.campaign_name,
                            Cell: (props: TableCell<DataRow>) => (
                                <>
                                    <CampaignStateIndicator
                                        className="me-2"
                                        state={props.original.campaign_state}
                                    />
                                    <LabelWithSubtext
                                        label={props.original.campaign_name}
                                        subtext={DATA_TYPES.ID.format(props.original.campaign_id)}
                                    />
                                </>
                            ),
                            id: 'name',
                        },
                        {
                            Header: 'Product Category',
                            Cell: (props: TableCell<DataRow>) => (
                                <Label>{props.original.product_category}</Label>
                            ),
                        },
                        {
                            Header: 'Product',
                            autoWidth: true,
                            autoWidthAdditionalWidth: 50,

                            Cell: (props: TableCell<DataRow>) => (
                                <>
                                    <InlineEditSelect
                                        value={props.original.product_label}
                                        onChange={(val) =>
                                            setUpdatedData(
                                                arrayItemUpdate(updatedData, props.index, {
                                                    ...updatedData[props.index],
                                                    product_label: val,
                                                    product_sub_label: '',
                                                    product_category:
                                                        productLabelOptions.find((op) => op.value === val)
                                                            ?.category ?? '',
                                                })
                                            )
                                        }
                                        options={productLabelOptions}
                                        placeholder="Select..."
                                        className="w-100"
                                        menuPosition="fixed"
                                    />
                                </>
                            ),
                        },
                        {
                            Header: 'Sub-Product',
                            autoWidth: true,
                            autoWidthAdditionalWidth: 50,
                            Cell: (props: TableCell<DataRow>) => (
                                <InlineEditSelect
                                    className="w-100"
                                    value={props.original.product_sub_label}
                                    onChange={(val) =>
                                        setUpdatedData(
                                            arrayItemUpdate(updatedData, props.index, {
                                                ...updatedData[props.index],
                                                product_sub_label: val,
                                            })
                                        )
                                    }
                                    options={getProductSubLabelOptions(
                                        arrangedProductLabels,
                                        props.original.product_label
                                    )}
                                    placeholder="Select..."
                                    menuPosition="fixed"
                                />
                            ),
                        },
                    ]}
                />
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <AnimatedButton disabled={loading} onClick={onApply}>
                        Update
                    </AnimatedButton>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default BulkCampaignProductSuggestModal;
