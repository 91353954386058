import { useDispatch, useSelector } from 'react-redux';
import { useLoading } from 'platform/common/hooks/useLoading';
import { wikiActions, wikiSelectors } from './wiki.duck';
import { fetchPages } from './wiki.service';

const useWikiPages = () => {
    const dispatch = useDispatch();
    const activeSpace = useSelector(wikiSelectors.activeWikiSpace);
    const wikiPages = useSelector(wikiSelectors.wikiPages);
    const [loading, withLoading] = useLoading();

    return {
        wikiPages,
        loading,
        reloadPages: () =>
            withLoading(() =>
                fetchPages(activeSpace).then((pages) => dispatch(wikiActions.setWikiPages(pages)))
            ),
    };
};

export default useWikiPages;
