import { Route, Routes } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { sortBy } from 'lodash-es';
import { costSourcesToList } from 'platform/campaign/globalPreset/globalPreset.mapper';
import { GlobalCostSourceListModel } from 'platform/campaign/globalPreset/globalPreset.types';
import {
    fetchAdvertiserCostSources,
    setDefaultCostSource,
} from 'platform/campaign/globalPreset/services/globalCostSourcePreset.service';
import ChannelLabel from 'platform/channels/components/ChannelLabel';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { precisionRound } from 'platform/common/utils/formatters.util';
import { history } from 'platform/store';
import GlobalCostSourceForm from './GlobalCostSourceForm';

export const PATH = '/advertiser-settings/global-cost-sources';

const GlobalCostSourceList = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const canEdit = useFeature('ADVERTISER_SETTINGS_EDIT');

    const [{ data, loading }, refetch] = usePromise<GlobalCostSourceListModel[]>(
        [],
        () =>
            fetchAdvertiserCostSources({ advertiserId }).then((loaded) =>
                sortBy(loaded.map(costSourcesToList), ['channel', 'name'])
            ),
        [advertiserId]
    );

    const renderTopToolbar = () => (
        <ListToolbar onCreate={canEdit ? () => history.push(`${PATH}/new`) : undefined} />
    );

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            maxWidth: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Channel',
            accessor: 'channel',
            type: DATA_TYPES.TEXT,
            maxWidth: 200,
            Cell: ({ original: { channel, channelDefault } }: TableCell<GlobalCostSourceListModel>) => {
                const defaultBadge = channelDefault && (
                    <Badge className="badge-outline" color="success">
                        Default
                    </Badge>
                );
                return channel ? (
                    <div>
                        {<ChannelLabel channel={channel} />} {defaultBadge}
                    </div>
                ) : (
                    defaultBadge
                );
            },
        },
        {
            Header: 'Agency fee',
            accessor: 'agencyFee',
            maxWidth: 100,
            type: DATA_TYPES.FLOAT,
            Cell: (props: TableCell<GlobalCostSourceListModel>) =>
                DATA_TYPES.P100.format(precisionRound(props.original.agencyFee * 100, 2)),
        },
        {
            className: 'pull-right cell-align-right',
            maxWidth: 50,
            sortable: false,
            Cell: ({ original: { id, channelDefault } }: TableCell<GlobalCostSourceListModel>) => {
                const items = [
                    {
                        label: canEdit ? 'Edit' : 'View',
                        action: () => history.push(`${PATH}/${id}`),
                    },
                ];
                if (!channelDefault) {
                    items.push({
                        label: 'Set as default',
                        action: () => setDefaultCostSource(id, advertiserId).then(() => refetch()),
                    });
                }

                return <InlineDropdown items={items} />;
            },
        },
    ];

    return (
        <>
            {(!canEdit || advertiserId) && (
                <BodyContainer helpKey="global_cost_sources_list">
                    {!canEdit && <CanNotEditWarning />}
                    {advertiserId && (
                        <FormattedTable
                            topToolbar={renderTopToolbar()}
                            columns={columns}
                            loading={loading}
                            data={data}
                            sortable={false}
                        />
                    )}
                </BodyContainer>
            )}
            <Routes>
                <Route
                    path="new"
                    element={
                        <GlobalCostSourceForm redirectTo={PATH} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <GlobalCostSourceForm redirectTo={PATH} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
            </Routes>
        </>
    );
};

export default GlobalCostSourceList;
