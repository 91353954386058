import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { push } from 'redux-first-history';
import { stringify } from 'qs';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterTreeByQuery } from 'platform/common/utils/search.util';
import ProductLabelFormContainer from './ProductLabelFormContainer';
import { fetchProducts, getProductCategories } from './productLabel.service';
import { Product, ProductCategory, ProductSubLabel } from './productLabel.types';

export const PATH = '/advertiser-settings/product-labels';

const DEFAULT_CATEGORY = '(uncategorized)';

type Row =
    | ({ type: 'CATEGORY'; children: Row[] } & ProductCategory)
    | ({ type: 'PRODUCT'; children: Row[] } & Product)
    | ({ type: 'SUB_LABEL' } & ProductSubLabel);

const categoryToRow = (category: ProductCategory, allProducts: Product[]): Row => ({
    type: 'CATEGORY',
    ...category,
    children: allProducts.filter((p) => (p.category || DEFAULT_CATEGORY) === category.name).map(productToRow),
});

const productToRow = (product: Product): Row => ({
    type: 'PRODUCT',
    ...product,
    children: product.subLabels.map((subLabel): Row => ({ type: 'SUB_LABEL', ...subLabel })),
});

const filterByStatus = <T extends { status: string }>(items: T[], statuses: string[]): T[] =>
    statuses.length ? items.filter((item) => statuses.includes(item.status)) : items;

type QueryParams = {
    status: ActiveOrArchived[];
    searchQuery: string;
};

const ProductLabelList = () => {
    const {
        queryParams: { status, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        searchQuery: '',
    });

    const dispatch = useDispatch();
    const canEdit = useFeature('ADVERTISER_SETTINGS_EDIT');
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const [{ data, loading }, refetch] = usePromise([], () => fetchProducts(advertiserId), [advertiserId]);

    const columnDefinitions: TableColumn<Row>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            width: 80,
            sortable: false,
            Cell: ({ original }) =>
                original.type === 'CATEGORY' ? (
                    <Badge color="secondary">Category</Badge>
                ) : (
                    <StatusBadge status={original.status} />
                ),
        },
        {
            Header: 'Label',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 300,
            sortable: false,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            sortable: false,
            Cell: ({ original }) =>
                original.type === 'PRODUCT' && (
                    <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />
                ),
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original }) => {
                if (original.type !== 'PRODUCT' || !canEdit) return null;
                return (
                    <InlineDropdown
                        items={[
                            {
                                label: 'Edit',
                                action: () =>
                                    dispatch(
                                        push({
                                            pathname: `${PATH}/${encodeURIComponent(original.name)}`,
                                            search: stringify({ advertiserId }),
                                        })
                                    ),
                            },
                        ]}
                    />
                );
            },
        },
    ];

    const products: Product[] = filterByStatus(data, status).map((product) => ({
        ...product,
        subLabels: filterByStatus(product.subLabels, status),
    }));

    const productCategories = getProductCategories(products);

    const rows = productCategories.length
        ? [{ name: DEFAULT_CATEGORY }, ...productCategories]
              .map((category) => categoryToRow(category, products))
              .filter((row) => 'children' in row && row.children.length)
        : products.map(productToRow);

    return (
        <>
            <BodyContainer helpKey="product_labels_list">
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    options={ACTIVE_OR_ARCHIVED}
                    onChange={(value) => setQueryParams({ status: value })}
                    isMulti
                />

                <FormattedTable
                    isTree
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () =>
                                          dispatch(
                                              push({
                                                  pathname: `${PATH}/new`,
                                                  search: stringify({ advertiserId }),
                                              })
                                          )
                                    : undefined
                            }
                            value={searchQuery}
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    columns={columnDefinitions}
                    data={filterTreeByQuery(rows, searchQuery, 'children', ['name'])}
                    loading={loading}
                    nodesExpanded={!!searchQuery}
                    defaultSorted={[{ orderBy: 'name', direction: 'ASC' }]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <ProductLabelFormContainer
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":name"
                    element={
                        <ProductLabelFormContainer
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default ProductLabelList;
