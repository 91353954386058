import { useDispatch, useSelector } from 'react-redux';
import { Card, Input, Label } from 'reactstrap';
import { analyticsModeOptions, datePickerModeOptions } from 'platform/analytics/analytics.constants';
import { AnalyticsMode, AnalyticsSettings, DatePickerMode } from 'platform/analytics/analytics.types';
import { makeGridColumnOptions } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { activeAdvertiserSelectors, AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import './ReportSettings.scss';

interface Props {
    settings: AnalyticsSettings;
    isAdmin: boolean;
    onModeChange: (mode: AnalyticsMode) => void;
}

const findAttribute = (
    advertiserIds: number[],
    advertiserOptions: AdvertiserOption[],
    attributeName: string
) =>
    advertiserIds.length === 1
        ? advertiserOptions.find((option) => option.id === advertiserIds[0])?.additionalAttributes?.[
              attributeName
          ]
        : undefined;

const ReportSettings = ({ settings, onModeChange, isAdmin }: Props) => {
    const dispatch = useDispatch();
    const advertiserIds = useSelector(activeAdvertiserSelectors.ids);
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);
    const { hasDefaultMetrics } = useSelector(analyticsSelectors.meta);
    const vatRate = findAttribute(advertiserIds, advertiserOptions, 'analytics.vat-rate');
    const optInRate = findAttribute(advertiserIds, advertiserOptions, 'analytics.opt-in-rate');

    const onDatePickerModeChange = (datePickerMode: DatePickerMode) => {
        dispatch(
            analyticsSettingsActions.changeSettings({
                ...settings,
                datePickerMode,
            })
        );
    };

    return (
        <Card className="my-3 px-3 flex-row flex-wrap align-items-center border-primary shadow-none">
            <div className="ReportSettings">
                <div>
                    <SelectWithAddon
                        name={'Mode'}
                        value={settings.mode}
                        options={analyticsModeOptions}
                        isDisabled={!isAdmin}
                        isClearable={false}
                        onChange={onModeChange}
                    />
                </div>
                {settings.mode !== 'INTERACTIVE' && (
                    <div>
                        <SelectWithAddon
                            name="Date picker mode"
                            value={settings.datePickerMode}
                            options={datePickerModeOptions}
                            isDisabled={!isAdmin}
                            isClearable={false}
                            onChange={onDatePickerModeChange}
                        />
                    </div>
                )}
                <div>
                    <SelectWithAddon
                        name="Grid columns"
                        value={settings.gridColumns}
                        options={makeGridColumnOptions(12)}
                        onChange={(value) => dispatch(analyticsSettingsActions.changeGridColumns(value))}
                    />
                </div>
            </div>

            <div className="ReportSettings-options">
                <Label className="d-flex align-items-center mb-0  cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={vatRate ? !!settings.includeVatRate : false}
                        disabled={!vatRate}
                        onChange={(e) =>
                            dispatch(analyticsSettingsActions.changeIncludeVatRate(e.target.checked))
                        }
                    />
                    <Tooltip
                        tooltipVisible={advertiserIds.length > 1 || !vatRate}
                        renderTooltip={() => {
                            if (advertiserIds.length > 1) {
                                return 'Only one Advertiser can be selected in the filter to display data with VAT';
                            }

                            if (!vatRate) {
                                return 'VAT is not defined for this Advertiser';
                            }

                            return null;
                        }}
                    >
                        <span className="ms-1 h-1">VAT included</span>
                    </Tooltip>
                </Label>
                <Label className="d-flex align-items-center mb-0  cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        disabled={!hasDefaultMetrics}
                        checked={hasDefaultMetrics ? settings.includeDefaultMetrics : false}
                        onChange={(e) =>
                            dispatch(
                                analyticsSettingsActions.changeSettings({
                                    ...settings,
                                    includeDefaultMetrics: e.target.checked,
                                })
                            )
                        }
                    />
                    <Tooltip
                        renderTooltip={() => {
                            if (!hasDefaultMetrics) {
                                return 'Default metrics are not defined for this advertiser';
                            }

                            return 'Adds default advertiser metrics to corresponding sections';
                        }}
                    >
                        <span className="ms-1 h-1">Default metrics</span>
                    </Tooltip>
                </Label>
                <Label className="d-flex align-items-center mb-0  cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={optInRate ? !!settings.modelOptIn : false}
                        disabled={!optInRate}
                        onChange={(e) =>
                            dispatch(analyticsSettingsActions.changeModelOptIn(e.target.checked))
                        }
                    />
                    <Tooltip
                        tooltipVisible={advertiserIds.length > 1 || !optInRate}
                        renderTooltip={() => {
                            if (advertiserIds.length > 1) {
                                return 'Only one Advertiser can be selected in the filter to model opt-in numbers';
                            }

                            if (!optInRate) {
                                return 'Opt-in rate is not defined for this Advertiser';
                            }

                            return null;
                        }}
                    >
                        <span className="ms-1 h-1">Opt-In Modelling</span>
                    </Tooltip>
                </Label>
                {isAdmin && (
                    <Label className="d-flex align-items-center mb-0  cursor-pointer">
                        <Input
                            type="checkbox"
                            className="position-static m-0"
                            checked={settings.debugMode}
                            onChange={(e) =>
                                dispatch(
                                    analyticsSettingsActions.changeSettings({
                                        ...settings,
                                        debugMode: e.target.checked,
                                    })
                                )
                            }
                        />
                        <span className="ms-1 h-1">Debug mode</span>
                    </Label>
                )}
                <Label className="d-flex align-items-center mb-0  cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={settings.inlineEditing}
                        onChange={(e) =>
                            dispatch(
                                analyticsSettingsActions.changeSettings({
                                    ...settings,
                                    inlineEditing: e.target.checked,
                                })
                            )
                        }
                    />
                    <span className="ms-1 h-1">Inline editing</span>
                </Label>
                <Label className="d-flex align-items-center mb-0  cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={settings.showLogBookLink === undefined || settings.showLogBookLink} // checking undefined for backward compatibility
                        onChange={(e) =>
                            dispatch(
                                analyticsSettingsActions.changeSettings({
                                    ...settings,
                                    showLogBookLink: e.target.checked,
                                })
                            )
                        }
                    />
                    <span className="ms-1 h-1">Log book link</span>
                </Label>
            </div>
        </Card>
    );
};

export default ReportSettings;
