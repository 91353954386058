import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, isNil, uniq } from 'lodash-es';
import { SummaryBarState, TableState } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { DefaultMetrics } from 'platform/campaign/advertiserManagement/DefaultMetrics/defaultMetrics.types';

type SupportedComponent = TableState | SummaryBarState;

const removePreviousDefaultMetrics = (metrics: string[], previousDefaultMetrics: string[] = []) =>
    metrics.filter((m) => !previousDefaultMetrics.includes(m));

const filterAvailableMetrics = (metrics: string[], compatibleColumns: string[]) =>
    metrics.filter((metric) => compatibleColumns.includes(metric));

const toggleDefaultMetrics = (
    metrics: string[],
    defaultMetrics: string[],
    compatibleColumns: string[],
    includeDefaultMetrics?: boolean
) => {
    if (includeDefaultMetrics) {
        return uniq([...metrics, ...filterAvailableMetrics(defaultMetrics, compatibleColumns)]);
    }

    return metrics.filter((metric) => !defaultMetrics.includes(metric));
};

export const toggleComponentDefaultMetrics = ({
    compatibleColumns,
    componentState,
    defaultMetrics,
    includeDefaultMetrics,
}: {
    componentState: SupportedComponent;
    compatibleColumns: string[];
    defaultMetrics: DefaultMetrics | null;
    includeDefaultMetrics?: boolean;
}): SupportedComponent => {
    const { tableMetrics, summaryBarFirstRowMetrics, summaryBarSecondRowMetrics } = {
        tableMetrics: [],
        summaryBarFirstRowMetrics: [],
        summaryBarSecondRowMetrics: [],
        ...defaultMetrics,
    };

    if (componentState.type === 'TABLE') {
        return {
            ...componentState,
            presets: componentState.presets.map((preset, index) =>
                componentState.activePresetIndex === index
                    ? {
                          ...preset,
                          metrics: toggleDefaultMetrics(
                              removePreviousDefaultMetrics(preset.metrics, componentState.defaultMetrics),
                              tableMetrics,
                              compatibleColumns,
                              includeDefaultMetrics
                          ),
                      }
                    : preset
            ),
            defaultMetrics: tableMetrics,
        };
    }

    if (componentState.type === 'SUMMARY_BAR') {
        const summaryBarDefaultMetrics = uniq([...summaryBarFirstRowMetrics, ...summaryBarSecondRowMetrics]);
        return {
            ...componentState,
            rows: componentState.rows.map((row, index) => {
                const metrics = removePreviousDefaultMetrics(row, componentState.defaultMetrics);
                if (index === 0)
                    return toggleDefaultMetrics(
                        metrics,
                        summaryBarFirstRowMetrics,
                        compatibleColumns,
                        includeDefaultMetrics
                    );
                if (index === 1)
                    return toggleDefaultMetrics(
                        metrics,
                        summaryBarSecondRowMetrics,
                        compatibleColumns,
                        includeDefaultMetrics
                    );
                return row;
            }),
            defaultMetrics: summaryBarDefaultMetrics,
        };
    }

    return componentState;
};

interface Props {
    componentState: SupportedComponent;
    compatibleMetrics: string[];
    compatibleMetricsLoading: boolean;
}

export const useDefaultMetrics = ({ componentState, compatibleMetrics, compatibleMetricsLoading }: Props) => {
    const [compatibleMetricsLoaded, setCompatibleMetricsLoaded] = useState(false);
    const dispatch = useDispatch();
    const { defaultMetrics } = useAnalyticsMetadata();
    const settings = useSelector(analyticsSelectors.settings);

    const handleDefaultMetricsToggle = () => {
        if (isNil(settings?.includeDefaultMetrics)) {
            return;
        }

        const newState = toggleComponentDefaultMetrics({
            componentState,
            compatibleColumns: compatibleMetrics,
            defaultMetrics,
            includeDefaultMetrics: settings?.includeDefaultMetrics,
        });

        if (!isEqual(newState, componentState)) {
            dispatch(analyticsSettingsActions.changeComponentState(newState));
        }
    };

    // on initial load we need to wait for compatible metrics to be loaded
    useEffect(() => {
        if (compatibleMetricsLoading) {
            setCompatibleMetricsLoaded(false);
        } else if (!compatibleMetricsLoaded) {
            handleDefaultMetricsToggle();
            setCompatibleMetricsLoaded(true);
        }
    }, [compatibleMetricsLoading]);

    // once compatible metrics are loaded, we only want to listen to includeDefaultMetrics changes
    // cause compatible metrics a reloaded every time metrics are changed
    useEffect(() => {
        if (compatibleMetricsLoaded) {
            handleDefaultMetricsToggle();
        }
    }, [settings?.includeDefaultMetrics]);
};
