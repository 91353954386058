import { useState } from 'react';
import classNames from 'classnames';
import DeeplyAiLogo from 'platform/assets/images/deeply-ai-logo.svg';
import IconButton from 'platform/common/components/IconButton/IconButton';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import ChatAgentBtn from '../ChatAgents/ChatAgentBtn';
import ChatClearButton from '../ChatClearButton';
import ChatFooter from '../ChatFooter/ChatFooter';
import ChatInput from '../ChatInput/ChatInput';
import ChatMessage from '../ChatMessage/ChatMessage';
import ChatWelcomeMessage from '../ChatMessage/customMessages/ChatWelcomeMessage';
import useChat from '../useChat';
import FullScreenChatLink from './FullScreenChatLink';
import './MinimizedChat.scss';

interface Props {
    onBackClick: () => void;
    className?: string;
}

const MinimizedChat = ({ onBackClick, className }: Props) => {
    const [messagesRef, setMessagesRef] = useState<HTMLDivElement | null>(null);

    const { agentsLoading, dropRef, messages, selectedAgent, contextPrompts, setSelectedAgent } = useChat();

    if (agentsLoading) return <OverlayLoader />;

    return (
        <form className={classNames('MinimizedChat', className)} ref={dropRef}>
            <div className="MinimizedChat-backgroundImg">
                <div className="MinimizedChat-header">
                    <IconButton
                        onClick={() => {
                            onBackClick();
                            setSelectedAgent(undefined);
                        }}
                        className="position-absolute"
                        style={{ left: 32 }}
                    >
                        <i className="far fa-chevron-left fs-5 text-white" />
                    </IconButton>
                    <img src={DeeplyAiLogo} className="MinimizedChat-logo" alt="DeeplyAI Logo" />
                    <ChatClearButton
                        className="position-absolute"
                        iconClassName="fs-4"
                        style={{ right: 80 }}
                    />
                    <FullScreenChatLink
                        className="position-absolute"
                        iconClassName="fs-4"
                        style={{ right: 32 }}
                    />
                </div>
                <div className="MinimizedChat-agent">
                    <ChatAgentBtn size="medium" agent={selectedAgent!} disabled />
                </div>
            </div>

            <div className="MinimizedChat-messages" ref={setMessagesRef}>
                <ChatWelcomeMessage className="MinimizedChat-message" />
                {messages.map((msg, index) => (
                    <ChatMessage
                        key={`chat_message_${index}`}
                        index={index}
                        messageData={msg}
                        contextPrompts={contextPrompts}
                        titleMessageClassName="bg-body"
                        iconUrl={selectedAgent?.icon}
                        className="MinimizedChat-message"
                    />
                ))}
            </div>
            <div className="position-relative p-3 bg-body">
                <ChatInput
                    chatBoundary={messagesRef}
                    className="MinimizedChat-input"
                    iconClassName="MinimizedChat-inputIcon"
                />
            </div>
            <ChatFooter className="pb-2" />
        </form>
    );
};

export default MinimizedChat;
