import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApolloError } from '@apollo/client';
import { isEmpty } from 'lodash-es';
import { getDimensionFilterOptions } from 'platform/analytics/analytics.service';
import {
    DimensionFilterOption,
    FilterDefinition,
    ReportFilterValue,
    ReportFilterWithOperator,
} from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { FilterOperator } from 'platform/common/components/FilterOperatorDropdown/FilterOperatorDropdown';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';

const resolveValues = (
    values: ReportFilterValue[],
    options: DimensionFilterOption[],
    operator?: FilterOperator,
    filterDefinition?: FilterDefinition
): ReportFilterValue[] => {
    if (!values.length || filterDefinition?.type !== 'LIST') {
        return values;
    }

    switch (operator) {
        case 'IS_NOT': {
            const resolved = options
                .filter((option) => !values.includes(option.value))
                .map((option) => option.value);
            return resolved.length ? resolved : [null];
        }
        case 'CONTAINS': {
            const resolved = options
                .filter((option) =>
                    values.some((value) =>
                        option.label.toLowerCase().includes(String(value).toLocaleLowerCase())
                    )
                )
                .map((option) => option.value);
            return resolved.length ? resolved : values;
        }
        case 'CONTAINS_NOT': {
            const resolved = options
                .filter(
                    (option) =>
                        !values.some((value) =>
                            option.label.toLowerCase().includes(String(value).toLocaleLowerCase())
                        )
                )
                .map((option) => option.value);
            return resolved.length ? resolved : values;
        }
        default:
            return values.filter((value) => options.some((option) => option.value === value));
    }
};

const resolveFilterValues = (
    filters: ReportFilterWithOperator[],
    filterDefinitions: FilterDefinition[],
    optionsMap?: FilterOptionsMap
): ReportFilterWithOperator[] =>
    filters.map((filter) => {
        const options = optionsMap?.[filter.key]?.options ?? [];
        const filterDefinition = filterDefinitions.find((definition) => definition.target === filter.key);

        return {
            ...filter,
            resolvedValues: resolveValues(filter.values, options, filter.operator, filterDefinition),
        };
    });

export const updateFilterInputAndValues = (
    filters: ReportFilterWithOperator[],
    filterDefinitions: FilterDefinition[],
    optionsMap?: FilterOptionsMap
): ReportFilterWithOperator[] =>
    filters.map((filter) => {
        const options = optionsMap?.[filter.key]?.options ?? [];

        const filterDefinition = filterDefinitions.find((definition) => definition.target === filter.key);
        return {
            ...filter,
            values: resolveFilterInput(filter, options, filterDefinition),
            resolvedValues: resolveValues(filter.values, options, filter.operator, filterDefinition),
        };
    });

const resolveFilterInput = (
    filter: ReportFilterWithOperator,
    options: DimensionFilterOption[],
    filterDefinition?: FilterDefinition
) => {
    if (filterDefinition?.type !== 'LIST') {
        return filter.values;
    }

    return !filter.operator || (filter.operator && ['IS', 'IS_NOT'].includes(filter.operator))
        ? filter.values.filter((value) => options.some((option) => option.value === value))
        : filter.values;
};

const updateFilters = (filters: ReportFilterWithOperator[], newFilter: ReportFilterWithOperator) => {
    if (filters.find((f) => f.key === newFilter.key)) {
        return filters.map((f) => (f.key === newFilter.key ? newFilter : f));
    }

    return [...filters, newFilter];
};

export const areFilterValuesResolved = (filters: ReportFilterWithOperator[]) =>
    filters.every((filter) => !!filter.resolvedValues);

export const fetchFilterOptionsMap = async (
    filtersToResolve: ReportFilterWithOperator[],
    filterDefinitions: FilterDefinition[],
    advertiserIds: number[]
) => {
    const filterOptionsPromises = filtersToResolve.map(async (filter) => {
        const filterDefinition = filterDefinitions.find((definition) => definition.target === filter.key);
        if (!filterDefinition) {
            return {
                ...filter,
                options: [],
                error: undefined,
            };
        }

        try {
            const options = await getDimensionFilterOptions(filterDefinition?.source, filter.values, [
                ...filtersToResolve
                    .filter((f) => f.key !== filter.key)
                    .map(({ key, values, resolvedValues }) => ({
                        key,
                        values: resolvedValues ?? values,
                    })),
                { key: 'advertiser_id', values: advertiserIds },
            ]);
            return {
                ...filter,
                error: undefined,
                options: options ?? [],
            };
        } catch (error) {
            return {
                ...filter,
                error,
                options: [],
            };
        }
    });

    const optionsMap: FilterOptionsMap = (await Promise.all(filterOptionsPromises)).reduce(
        (acc, filter) => ({
            ...acc,
            [filter.key]: {
                options: filter.options,
                error: filter.error,
            },
        }),
        {}
    );

    return isEmpty(optionsMap) ? undefined : optionsMap;
};

export type FilterOptionsMap = Record<
    string,
    {
        options: DimensionFilterOption[];
        error?: ApolloError;
    }
>;

export const useReportFilterOptions = () => {
    const { definitions } = useAnalyticsMetadata();
    const filterDefinitions = definitions.filters;
    const advertiserIds = useSelector(activeAdvertiserSelectors.ids);
    const filtersWithOperator = useSelector(analyticsSelectors.filtersWithOperator);
    const [loading, setLoading] = useState(false);
    const [filterOptionsMap, setFilterOptionsMap] = useState<FilterOptionsMap | undefined>(undefined);

    const shouldUpdateOptions = (filters: ReportFilterWithOperator[]) =>
        filters.some(({ operator }) => operator && operator !== 'IS');

    const resolveFilterOptions = async (
        filters: ReportFilterWithOperator[]
    ): Promise<ReportFilterWithOperator[]> => {
        setLoading(true);

        const optionsMap = await fetchFilterOptionsMap(filters, filterDefinitions, advertiserIds);
        const resolvedFilters = resolveFilterValues(filters, filterDefinitions, optionsMap);
        const updatedMap = await (shouldUpdateOptions(resolvedFilters)
            ? fetchFilterOptionsMap(resolvedFilters, filterDefinitions, advertiserIds)
            : optionsMap);

        const updatedFilters = updateFilterInputAndValues(resolvedFilters, filterDefinitions, updatedMap);

        setFilterOptionsMap(updatedMap);
        setLoading(false);
        return updatedFilters;
    };

    const changeFilter = async (filter: ReportFilterWithOperator): Promise<ReportFilterWithOperator[]> => {
        const previousFilter = filtersWithOperator.find((f) => f.key === filter.key);
        const filters = updateFilters(filtersWithOperator, filter);
        if (previousFilter && !previousFilter.values.length && !filter.values.length) {
            return filters;
        }
        setLoading(true);

        const resolvedFilters = resolveFilterValues(filters, filterDefinitions, filterOptionsMap);
        const updatedMap = await fetchFilterOptionsMap(resolvedFilters, filterDefinitions, advertiserIds);
        const updatedFilters = updateFilterInputAndValues(resolvedFilters, filterDefinitions, updatedMap);

        setFilterOptionsMap(updatedMap);
        setLoading(false);
        return updatedFilters;
    };

    return {
        loading,
        filterOptionsMap,
        resolveFilterOptions,
        changeFilter,
    };
};
