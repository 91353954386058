import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import AttachmentsEdit from 'platform/common/components/AttachmentEdit/AttachmentsEdit';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import ShowSettingsButton, {
    Settings,
} from 'platform/common/components/ShowSettingsButton/ShowSettingsButton';
import Unauthorized from 'platform/common/components/Unauthorized/Unauthorized';
import { usePromise } from 'platform/common/hooks/usePromise';
import useToggle from 'platform/common/hooks/useToggle';
import useWikiPermissions from '../useWikiPermissions';
import { wikiActions, wikiSelectors } from '../wiki.duck';
import {
    deleteAttachment,
    downloadAttachment,
    fetchAttachments,
    fetchPage,
    fetchPageContent,
    uploadAttachment,
} from '../wiki.service';
import WikiPageContent from './WikiPageContent';
import WikiPageHeader from './WikiPageHeader';
import './WikiPage.scss';

const WikiPage = () => {
    const dispatch = useDispatch();
    const wikiSpace = useSelector(wikiSelectors.activeWikiSpace);
    const { canEdit, allowedSpaces } = useWikiPermissions();
    const [editMode, toggleEditMode] = useToggle(false);
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const [{ data: pageData, loading: loadingPageData, error: errorPageData }, refetchPageData] = usePromise(
        undefined,
        async () => fetchPage(id),
        [id]
    );
    const [{ data: pageContent, loading: loadingPageContent, error: errorPageContent }, refetchPageContent] =
        usePromise(undefined, async () => fetchPageContent(id), [id]);
    const [{ data: attachments }, refetchAttachments] = usePromise([], async () => fetchAttachments(id), [
        id,
    ]);

    useEffect(() => {
        if (pageData && wikiSpace !== pageData.space) {
            dispatch(wikiActions.setActiveWikiSpace(pageData.space));
        }
    }, [pageData]);

    if (loadingPageData || loadingPageContent) {
        return <OverlayLoader />;
    }

    if (pageData && !allowedSpaces.includes(pageData.space)) {
        return <Unauthorized />;
    }

    if (errorPageData || errorPageContent || !pageData || pageContent === undefined) {
        return <ErrorMessage className="h-100" error={errorPageData || errorPageContent} />;
    }

    const handleModeChange = (newMode: Settings) => {
        toggleEditMode();

        if (newMode === 'view') {
            refetchPageContent();
            refetchPageData();
        }
    };

    return (
        <div className="WikiPage">
            <HeaderContainer className="d-flex align-items-center justify-content-between">
                <WikiPageHeader id={id} title={pageData.title} editMode={editMode} />
                <div className="d-flex align-items-center">
                    {canEdit && <ShowSettingsButton toggled={editMode} onClick={handleModeChange} />}
                </div>
            </HeaderContainer>
            <BodyContainer className="flex-grow-1 m-0" childClassName="h-100 d-flex flex-column">
                <WikiPageContent id={id} editMode={editMode} htmlContent={pageContent} />
                <AttachmentsEdit
                    className="mt-2"
                    attachments={attachments}
                    labelClassName="flex-grow-0 ms-2"
                    onUpload={(name, content) => uploadAttachment(id, name, content).then(refetchAttachments)}
                    onDelete={(pageId) => deleteAttachment(pageId).then(refetchAttachments)}
                    onDownload={downloadAttachment}
                />
            </BodyContainer>
        </div>
    );
};

export default WikiPage;
