import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Button } from 'reactstrap';
import { push } from 'redux-first-history';
import { intersection, isEmpty } from 'lodash-es';
import moment from 'moment';
import { listMediaplans, searchMediaInsertions } from 'platform/campaign/campaign/services/mediaplan.service';
import LogBookCreate from 'platform/campaign/logBook/components/LogBookCreate';
import LogBookEdit from 'platform/campaign/logBook/components/LogBookEdit';
import {
    FilterBy,
    LOG_CATEGORY_OPTIONS,
    LogCategory,
    LogDateFilterStrategy,
} from 'platform/campaign/logBook/logBook.constant';
import { toTableView } from 'platform/campaign/logBook/logBook.mapper';
import { LogBookEntry } from 'platform/campaign/logBook/logBook.types';
import { searchLogBook } from 'platform/campaign/logBook/logbook.service';
import { LogBookAccess, useLogBookAccess } from 'platform/campaign/logBook/useLogBookAccess';
import { SelectItem } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import DateRangePicker, {
    DateRangePickerProps,
} from 'platform/common/components/DateRangePicker/DateRangePicker';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { MediaInsertionSearchResult, Mediaplan } from 'platform/mediaplan/mediaplan.types';
import { useLabelClassification } from '../useLabelClassification';
import LogBookListTable from './LogBookListTable/LogBookListTable';

const categoryOptions = (access: LogBookAccess): SelectItem<LogCategory>[] =>
    access.canViewAll
        ? LOG_CATEGORY_OPTIONS
        : LOG_CATEGORY_OPTIONS.filter(
              (o) =>
                  (o.value === 'BUDGET_SHIFTING' && access.canViewBudgetShifting) ||
                  (o.value === 'OPTIMIZATION' && access.canViewOptimization)
          );

const nameGetter = (item: { name: string }) => item.name;

type QueryParams = {
    dateFrom: string;
    dateTo: string;
    dateFilterStrategy?: LogDateFilterStrategy;
    categoryFilter?: LogCategory;
    channelFilter: string[];
    mediaplans: number[];
    mediaInsertions: number[];
    productCategory: string[];
    productLabel: string[];
    productSubLabel: string[];
    flightLabel: string[];
    countryLabel: [];
    status: ActiveOrArchived[];
    filterBy?: FilterBy;
};

const LogBookList = () => {
    const {
        queryParams: {
            dateFrom,
            dateTo,
            dateFilterStrategy,
            categoryFilter,
            filterBy,
            channelFilter,
            mediaplans,
            mediaInsertions,
            productCategory,
            productLabel,
            productSubLabel,
            flightLabel,
            countryLabel,
            status,
        },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        dateFrom: moment().subtract(30, 'days').format(ISO_DATE_FORMAT),
        dateTo: moment().format(ISO_DATE_FORMAT),
        dateFilterStrategy: undefined,
        categoryFilter: undefined,
        filterBy: undefined,
        channelFilter: [],
        mediaplans: [],
        mediaInsertions: [],
        productCategory: [],
        productLabel: [],
        productSubLabel: [],
        flightLabel: [],
        countryLabel: [],
    });
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const logBookAccess = useLogBookAccess();
    const canEdit = logBookAccess.canEdit;
    const channels = useSelector(classifierSelectors.channels).filter((c) => c.status === 'ACTIVE');
    const dispatch = useDispatch();

    const { productCategories, products, subLabels, flightLabels, countryOptions, labelsLoading } =
        useLabelClassification({
            selectedProductCategories: productCategory,
            selectedProductLabels: productLabel,
        });

    const [{ data: logs, loading: logsLoading }, refetch] = usePromise<LogBookEntry[]>(
        [],
        () =>
            searchLogBook({
                status,
                category: categoryFilter,
                mediaplanId: mediaplans,
                mediaInsertionId: mediaInsertions,
                dateFrom,
                dateTo,
                dateFilterStrategy: filterBy === FilterBy.NOTE_PERIOD ? dateFilterStrategy : undefined,
                advertiserId,
                productCategory,
                productLabel,
                productSubLabel,
                flightLabel,
                countryLabel,
            }),
        [
            advertiserId,
            status,
            mediaplans,
            mediaInsertions,
            categoryFilter,
            dateFrom,
            dateTo,
            productCategory,
            productLabel,
            productSubLabel,
            flightLabel,
            countryLabel,
        ]
    );

    const [{ data: plans, loading: plansLoading }] = usePromise<Mediaplan[]>(
        [],
        () => listMediaplans({ advertiserId, status: ['ACTIVE'] }),
        [advertiserId]
    );

    const [{ data: insertions, loading: insertionsLoading }] = usePromise<MediaInsertionSearchResult[]>(
        [],
        () => {
            if (!isEmpty(mediaplans)) {
                return searchMediaInsertions({ advertiserId, mediaplanId: mediaplans });
            }
            return Promise.resolve([]);
        },
        [mediaplans]
    );

    const filteredLogs = channelFilter.length
        ? logs.filter((l) => l.mediaInsertions.some((mi) => intersection(mi.channels, channelFilter).length))
        : logs;

    const datepickerRender = (params?: Partial<DateRangePickerProps>) => (
        <DateRangePicker
            className="ms-auto"
            from={dateFrom || undefined}
            to={dateTo || undefined}
            onChange={(from, to) => {
                setQueryParams({
                    dateFrom: from,
                    dateTo: to,
                });
            }}
            {...params}
        />
    );

    return (
        <>
            <HeaderContainer>
                <div className="d-flex flex-wrap align-items-center">
                    <PageHeader
                        title="Log Book"
                        collapsedContent={datepickerRender({ className: 'borderless' })}
                    />
                    {datepickerRender()}
                </div>
            </HeaderContainer>
            <BodyContainer helpKey="log_book_list">
                {!canEdit && <CanNotEditWarning />}
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Category"
                        value={categoryFilter}
                        options={categoryOptions(logBookAccess)}
                        onChange={(value) => setQueryParams({ categoryFilter: value })}
                    />
                    <SelectWithAddon
                        name="Channel"
                        value={channelFilter}
                        options={channels}
                        getOptionValue={(c) => c.code}
                        getOptionLabel={(c) => c.name}
                        onChange={(value) => setQueryParams({ channelFilter: value })}
                        isMulti
                    />
                    <SelectWithAddon
                        name="Mediaplan"
                        value={mediaplans}
                        options={plans.map((m) => ({ value: m.id, label: m.name }))}
                        onChange={(value) => setQueryParams({ mediaplans: value })}
                        isLoading={plansLoading}
                        isMulti
                    />
                    {!isEmpty(mediaplans) && (
                        <SelectWithAddon
                            name="Media Insertion"
                            value={mediaInsertions}
                            options={insertions.map((m) => ({ value: m.id, label: m.name }))}
                            onChange={(value) => setQueryParams({ mediaInsertions: value })}
                            isLoading={insertionsLoading}
                            isMulti
                        />
                    )}
                    <SelectWithAddon
                        getOptionLabel={nameGetter}
                        getOptionValue={nameGetter}
                        isMulti
                        name="Product category"
                        value={productCategory}
                        onChange={(value) => setQueryParams({ productCategory: value })}
                        options={productCategories}
                        isLoading={labelsLoading}
                    />
                    <SelectWithAddon
                        getOptionLabel={nameGetter}
                        getOptionValue={nameGetter}
                        isMulti
                        name="Product"
                        value={productLabel}
                        onChange={(value) => setQueryParams({ productLabel: value, productSubLabel: [] })}
                        options={products}
                        isLoading={labelsLoading}
                    />
                    {!!subLabels.length && (
                        <SelectWithAddon
                            getOptionLabel={nameGetter}
                            getOptionValue={nameGetter}
                            isMulti
                            name="Sub-product"
                            value={productSubLabel}
                            onChange={(value) => setQueryParams({ productSubLabel: value })}
                            options={subLabels}
                            isLoading={labelsLoading}
                        />
                    )}
                    <SelectWithAddon
                        getOptionLabel={nameGetter}
                        getOptionValue={nameGetter}
                        isMulti
                        name="Flight"
                        onChange={(value) => setQueryParams({ flightLabel: value })}
                        value={flightLabel}
                        options={flightLabels}
                        isLoading={labelsLoading}
                    />
                    <SelectWithAddon
                        name="Country"
                        isMulti
                        value={countryLabel}
                        onChange={(value) => setQueryParams({ countryLabel: value })}
                        options={countryOptions}
                        isLoading={labelsLoading}
                    />
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={ACTIVE_OR_ARCHIVED}
                        onChange={(value) => setQueryParams({ status: value })}
                        isMulti
                    />
                </ListFilters>
                <Button
                    size="md"
                    color="primary"
                    className="mb-4"
                    onClick={() => dispatch(push('/logs/logbook/new'))}
                    disabled={!canEdit}
                >
                    Add New
                </Button>
                <LogBookListTable
                    isLoading={logsLoading}
                    canEdit={canEdit}
                    advertiserId={advertiserId}
                    logs={filteredLogs.map((e) => toTableView(e, channels))}
                    refetchLogs={refetch}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="logbook/new"
                    element={
                        <LogBookCreate
                            advertiserId={advertiserId}
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path="logbook/:id"
                    element={<LogBookEdit redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />}
                />
            </Routes>
        </>
    );
};

export default LogBookList;
