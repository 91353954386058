import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import { Location } from 'history';
import { isEqual, uniq } from 'lodash-es';
import moment from 'moment';
import qs from 'qs';
import AdvertiserFormContainer from 'platform/advertisers/components/AdvertiserForm/AdvertiserFormContainer';
import { ANALYTICS } from 'platform/analytics/analytics.navigation';
import {
    analyticsSettingsToApi,
    getCompatibleFilters,
    getFilterNumberValue,
    periodsToApi,
} from 'platform/analytics/analytics.util';
import {
    analyticsSettingsActions,
    DEFAULT_ANALYTICS_SETTINGS,
} from 'platform/analytics/ducks/analyticsSettings.duck';
import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';
import ArchivedWarning from 'platform/analytics/reportComponents/ArchivedWarning';
import InteractionPresets from 'platform/analytics/reportComponents/InteractionPresets/InteractionPresets';
import LinkGenerationModal from 'platform/analytics/reportComponents/LinkGenerationModal';
import LogBookLink from 'platform/analytics/reportComponents/LogBookLink';
import ReportFilters from 'platform/analytics/reportComponents/ReportFilters/ReportFilters';
import ReportSaveModal from 'platform/analytics/reportComponents/ReportSave/ReportSaveModal';
import ReportSettings from 'platform/analytics/reportComponents/ReportSettings';
import { SummaryBarProps } from 'platform/analytics/reportComponents/ReportSummaryBar/ReportSummaryBar';
import { TableProps } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableContainer';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { isDefined } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import DoubleDateRangePicker from 'platform/common/components/DoubleDateRangePicker/DoubleDateRangePicker';
import FloatingButton from 'platform/common/components/FloatingButton/FloatingButton';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InfoIconAlert from 'platform/common/components/InfoIconAlert/InfoIconAlert';
import { useModal } from 'platform/common/components/Modal/Modal';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import ShowSettingsButton from 'platform/common/components/ShowSettingsButton/ShowSettingsButton';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import {
    activeAdvertiserActions,
    activeAdvertiserSelectors,
} from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterActions, dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { parseQuery } from 'platform/common/utils/url.util';
import { createCustomReport } from 'platform/customReports/customReport.service';
import { CustomReportWithSettings } from 'platform/customReports/customReport.types';
import UnitFormContainer from 'platform/units/components/UnitForm/UnitFormContainer';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';
import { AnalyticsMode, CompareOption, NavigationParams } from '../analytics.types';
import { analyticsSelectors } from '../ducks/analytics.duck';
import AnalyticsComponents from '../reportComponents/AnalyticsComponents';
import LinkedReportWarning from '../reportComponents/LinkedReportWarning';
import OriginalReportLink from '../reportComponents/OriginalReportLink';
import ReportComponentWizard from '../reportComponents/ReportComponentActions/ReportComponentWizard';

const DATEPICKER_SHOWN_DATES = {
    fromDate: moment(2017, 'year'),
    toDate: moment().add(1, 'year'),
};

export interface ComponentsProps {
    SUMMARY_BAR?: SummaryBarProps;
    TABLE?: TableProps;
}

export interface FilterInputProps {
    isMulti?: boolean;
}

export interface AnalyticsProps {
    compareOptions?: CompareOption[];
    componentsProps?: ComponentsProps;
    filterInputProps?: { [key: string]: FilterInputProps };
    datePickerDisabled?: boolean;
    datePickerVisible?: boolean;
    monthPickerVisible?: boolean;
    filtersVisible?: boolean;
    interactionPresetsVisible?: boolean;
}

type Props = {
    location: Location;
    report?: CustomReportWithSettings;
    editMode: boolean;
    onModeChange: (mode: AnalyticsMode) => void;
    onSettingsToggle: () => void;
} & AnalyticsProps;

const Analytics = ({
    componentsProps,
    location,
    report,
    filterInputProps,
    compareOptions,
    editMode,
    datePickerDisabled = false,
    datePickerVisible = true,
    monthPickerVisible = false,
    filtersVisible = true,
    interactionPresetsVisible = false,
    onModeChange,
    onSettingsToggle,
}: Props) => {
    const { internalLinkId }: NavigationParams = parseQuery(location.search);
    const profile = useSelector(authSelectors.ready.profile);
    const isMobile = useSelector(layoutSelectors.isMobile);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const monthFilter = useSelector(dateFilterSelectors.month);
    const filters = useSelector(analyticsSelectors.ownFilters);
    const settings = useSelector(analyticsSelectors.settings);
    const compareType = useSelector(analyticsSelectors.compareType);
    const reportComponents = useSelector(analyticsSelectors.reportComponents);
    const activeAdvertisers = useSelector(activeAdvertiserSelectors.activeAdvertisers);
    const { accessibleReports } = useCustomReports();
    const isAdmin = useSelector(authSelectors.isAdmin);
    const hasAnalyticsEdit = useFeature('ANALYTICS_EDIT');
    const hasPersonal = useFeature('ANALYTICS_PERSONAL_SECTION_EDIT');
    const hasFolderAccess = useFeature('CUSTOM_FOLDER_EDIT');
    const { showModal } = useModal();
    const dispatch = useDispatch();
    const internalLinkReport = internalLinkId
        ? accessibleReports.find((r) => r.id === internalLinkId)
        : undefined;
    const reportName = (internalLinkId ? internalLinkReport?.name : report?.name) ?? 'Analytics';

    const activeAdvertiserIds = activeAdvertisers.map((a) => a.id);

    const generateLink = async (saveAdvertisers: boolean, saveDate: boolean) => {
        const originalReport: Partial<CustomReportWithSettings> = {
            ...report,
            analyticsSettings: report?.analyticsSettings && analyticsSettingsToApi(report.analyticsSettings),
        };

        const analyticsSettings = analyticsSettingsToApi(settings);

        const newReport: CustomReportWithSettings = {
            ...report,
            analyticsSettings,
            advertiserIds: saveAdvertisers ? activeAdvertiserIds : report?.advertiserIds,
            periods: saveDate ? periodsToApi(periods) : undefined,
            userIds: [],
            assigneeIds: [],
            name: reportName,
            type: report?.type ?? 'STANDARD',
            status: report?.status ?? 'ACTIVE',
            seatIds: report?.seatIds ?? uniq(activeAdvertisers.map((a) => a.seatId).filter(isDefined)),
        };

        const reportId = isEqual(originalReport, newReport)
            ? originalReport?.id
            : await createCustomReport({
                  ...newReport,
                  section: undefined,
                  analyticsSettings: {
                      ...DEFAULT_ANALYTICS_SETTINGS,
                      ...(newReport.analyticsSettings ?? {}),
                      originalReportId: originalReport?.id,
                  },
                  systemReportKey: undefined,
              });

        return `${window.location.origin}${ANALYTICS.path}?${qs.stringify({ reportId })}`;
    };

    if (profile.accessibleChannels?.length) {
        const compatibleFilters = getCompatibleFilters(reportComponents);
        if (compatibleFilters && !compatibleFilters.find((f) => f.target === 'channel')) {
            return (
                <InfoIconAlert className="m-3">
                    We are sorry, but this report is not available to channel-restricted users.
                </InfoIconAlert>
            );
        }
    }

    const renderDatePicker = (
        <>
            {datePickerVisible && (
                <>
                    <DoubleDateRangePicker
                        {...periods}
                        compareType={compareType}
                        className="borderless"
                        mode={settings.datePickerMode}
                        disabled={datePickerDisabled}
                        showCompareValues={settings.showCompareValues}
                        compareOptions={compareOptions}
                        onChange={(params) => dispatch(dateFilterActions.changePeriods(params))}
                        onShowCompareValuesChange={(showCompareValues) =>
                            dispatch(
                                analyticsSettingsActions.changeSettings({
                                    ...settings,
                                    showCompareValues,
                                })
                            )
                        }
                        {...DATEPICKER_SHOWN_DATES}
                    />
                </>
            )}

            {monthPickerVisible && (
                <SelectWithAddon
                    name="Month"
                    value={monthFilter}
                    className="ms-2 w-auto"
                    options={moment.months().map((month, index) => ({ label: month, value: index + 1 }))}
                    onChange={(month) => dispatch(dateFilterActions.changeMonth(month))}
                />
            )}
        </>
    );

    return (
        <>
            <HeaderContainer>
                <div className="d-flex align-items-center flex-wrap">
                    <ArchivedWarning report={report} />

                    <PageHeader title={reportName} collapsedContent={renderDatePicker} />
                    <Button
                        className="p-0 ms-2"
                        color="link"
                        onClick={() =>
                            showModal((toggle) => (
                                <LinkGenerationModal onGenerateClick={generateLink} toggle={toggle} />
                            ))
                        }
                    >
                        <Tooltip renderTooltip={() => 'Generate link to share report'}>
                            <i className="fal fa-link" />
                        </Tooltip>
                    </Button>
                    {(isAdmin || hasPersonal || hasFolderAccess) && (
                        <Button
                            className="p-0 ms-2"
                            color="link"
                            onClick={() =>
                                showModal((toggle) => (
                                    <ReportSaveModal
                                        toggle={toggle}
                                        isLinked={!!internalLinkId}
                                        reportId={report?.id}
                                        name={reportName}
                                    />
                                ))
                            }
                        >
                            <Tooltip renderTooltip={() => 'Pin this report to Analytics'}>
                                <i className="fal fa-thumbtack" />
                            </Tooltip>
                        </Button>
                    )}
                    <OriginalReportLink report={report} />

                    {interactionPresetsVisible && report?.id && (
                        <div className="mx-3">
                            <InteractionPresets reportId={report?.id} />
                        </div>
                    )}

                    <div className="ms-auto d-flex flex-wrap align-items-center justify-content-end">
                        {!isMobile && activeAdvertiserIds.length === 1 && (
                            <LogBookLink
                                advertiserId={activeAdvertiserIds[0]}
                                mediaInsertionId={getFilterNumberValue(filters, 'media_insertion_id')}
                                mediaplanId={getFilterNumberValue(filters, 'media_plan_id')}
                                dateFrom={periods.primaryFrom}
                                dateTo={periods.primaryTo}
                            />
                        )}

                        {renderDatePicker}

                        {hasAnalyticsEdit && (
                            <ShowSettingsButton toggled={editMode} onClick={onSettingsToggle} />
                        )}
                    </div>
                </div>
            </HeaderContainer>

            <div className="m-3">
                {profile?.seatId === ADMIN_SEAT_ID && editMode && internalLinkId && <LinkedReportWarning />}

                {editMode && hasAnalyticsEdit && (
                    <ReportSettings settings={settings} isAdmin={isAdmin} onModeChange={onModeChange} />
                )}
            </div>

            {filtersVisible && (
                <ReportFilters
                    filterInputProps={filterInputProps}
                    showCampaignFilters={report?.systemReportKey === 'campaign_list'}
                />
            )}

            {settings?.maintenanceMode && (
                <Alert color="danger" className="m-3 p-2">
                    <i className="fas fa-triangle-exclamation ms-1 me-2" />
                    <span>This report is currently being reviewed and should not be used at this time</span>
                </Alert>
            )}

            <BodyContainer>
                <div
                    style={
                        isMobile
                            ? undefined
                            : {
                                  display: 'grid',
                                  gridGap: '0.75rem',
                                  gridTemplateColumns: `repeat(${settings.gridColumns}, 1fr)`,
                                  paddingBottom: 80,
                              }
                    }
                >
                    <AnalyticsComponents
                        reportComponents={reportComponents}
                        isMobile={isMobile}
                        report={report}
                        componentsProps={componentsProps}
                        editMode={editMode}
                        settings={settings}
                    />

                    {editMode && hasAnalyticsEdit && (
                        <FloatingButton
                            icon="fa fa-plus"
                            onClick={async () =>
                                showModal((toggle) => (
                                    <ReportComponentWizard
                                        onClose={toggle}
                                        componentsProps={componentsProps}
                                        canEdit={isAdmin}
                                    />
                                ))
                            }
                        >
                            Add new section
                        </FloatingButton>
                    )}
                </div>
            </BodyContainer>
            <Routes>
                <Route
                    path="advertiser/edit/:id"
                    element={
                        <AdvertiserFormContainer
                            type="ADVERTISER"
                            redirectTo={`/analyticsPro${location?.search ?? ''}`}
                            canEdit
                            afterSubmit={() => dispatch(activeAdvertiserActions.reloadAdvertiserOptions())}
                        />
                    }
                />
                <Route
                    path="unit-spend/:id"
                    element={
                        <UnitFormContainer canEdit redirectTo={`/analyticsPro${location.search ?? ''}`} />
                    }
                />
            </Routes>
        </>
    );
};

export default Analytics;
