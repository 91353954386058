import { AdvertiserExternalIntegration } from 'platform/advertisers/advertiser.types';
import GoogleExternalCredentials from 'platform/advertisers/components/AdvertiserFormIntegrations/GoogleExternalCredentials';
import MultipleExternalAccounts from 'platform/advertisers/components/AdvertiserFormIntegrations/MultipleExternalAccounts';
import SourceMediumModal from 'platform/advertisers/components/AdvertiserFormIntegrations/SourceMediumModal/SourceMediumModal';
import FormRow from 'platform/common/components/FormRow/FormRow';
import IntegrationIcon from 'platform/common/components/IntegrationIcon/IntegrationIcon';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import { useModal } from 'platform/common/components/Modal/Modal';
import Separator from 'platform/common/components/Separator/Separator';
import { additionalAttributeFieldName } from 'platform/common/hooks/useAdvancedSetting';
import FormDynamicOptionList from 'platform/formik/FormDynamicOptionList/FormDynamicOptionList';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import {
    ExternalSystem,
    getIntegrationLabel,
    IMPORTED_INTEGRATIONS,
} from '../../../common/constants/externalIntegration.constant';
import GcmAccountFormBlock from './GcmAccountFormBlock';
import '../AdvertiserForm/AdvertiserForm.scss';

type Props = {
    field: string;
    onChange: (field: string, value: any) => void;
    integration: AdvertiserExternalIntegration;
    advertiserId?: number;
    advertiserName?: string;
};

export const ADVERTISER_INTEGRATION_HELP_KEY = `advertiser_integrations_form_integration`;
const SINGLE_ACCOUNT_INTEGRATIONS: ExternalSystem[] = ['DV360', 'TWITTER', 'TIKTOK'];

const AdvertiserIntegration = ({
    field,
    integration: { externalSystem, enabled, accounts, settings },
    onChange,
    advertiserId,
    advertiserName,
}: Props) => {
    const { showModal } = useModal();

    return (
        <>
            <div className="py-2">
                <div className="d-flex align-items-center">
                    <FormSwitch name={`${field}.enabled`} />
                    <div className="ps-1">
                        <IntegrationIcon system={externalSystem} enabled={enabled} />
                    </div>
                    <div className="ps-1 ms-1">{getIntegrationLabel(externalSystem)}</div>
                </div>
            </div>
            {enabled && (
                <div className="AdvertiserIntegrations-fieldGroup">
                    {IMPORTED_INTEGRATIONS.includes(externalSystem) && (
                        <>
                            {SINGLE_ACCOUNT_INTEGRATIONS.includes(externalSystem) && (
                                <FormRow
                                    label="External account ID"
                                    helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_externalId`}
                                >
                                    <FormInput
                                        name={`${field}.accounts[0].externalId`}
                                        className="AdvertiserIntegrations-longField"
                                    />
                                </FormRow>
                            )}
                            {!SINGLE_ACCOUNT_INTEGRATIONS.includes(externalSystem) && (
                                <MultipleExternalAccounts
                                    accounts={accounts}
                                    externalSystem={externalSystem}
                                    onChange={onChange}
                                    field={field}
                                />
                            )}
                            <FormRow
                                helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_autoImport`}
                                label="Automatic re-import"
                            >
                                <FormSwitch name={`${field}.autoImport`} />
                            </FormRow>
                            {externalSystem === 'FACEBOOK' && (
                                <>
                                    <Separator label="Optional" className="mb-0 pb-0" />
                                    <FormRow
                                        label="Facebook Pixel ID"
                                        helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_facebook_pixel_id`}
                                    >
                                        <FormInput
                                            name={additionalAttributeFieldName('facebook.pixel-id')}
                                            className="AdvertiserIntegrations-longField"
                                        />
                                    </FormRow>
                                    <FormRow
                                        label="Facebook Page ID"
                                        helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_facebook_page_id`}
                                    >
                                        <FormInput
                                            name={additionalAttributeFieldName('facebook.page-id')}
                                            className="AdvertiserIntegrations-longField"
                                        />
                                    </FormRow>
                                </>
                            )}
                            {(externalSystem === 'GOOGLE' || externalSystem === 'BING') && (
                                <>
                                    <Separator label="Optional" className="mb-0 pb-0" />
                                    <FormRow
                                        label="Account ID for access management"
                                        helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_accessManagementAccountId`}
                                    >
                                        <FormInput
                                            name={`${field}.settings.accessManagementAccountId`}
                                            className="AdvertiserIntegrations-longField"
                                        />
                                    </FormRow>
                                </>
                            )}
                        </>
                    )}
                    {externalSystem === 'GCM' && (
                        <GcmAccountFormBlock
                            field={`${field}.accounts[0]`}
                            helpKey={field}
                            account={accounts[0]}
                        />
                    )}
                    {externalSystem === 'GOOGLE_ANALYTICS' && (
                        <>
                            <FormRow
                                label="View ID"
                                helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_externalId`}
                            >
                                <FormInput
                                    name={`${field}.accounts[0].externalId`}
                                    className="AdvertiserIntegrations-longField"
                                />
                            </FormRow>
                            <FormRow
                                label="Append UA tracking parameter"
                                helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_universalAnalyticsTrackingParameterEnabled`}
                            >
                                <FormSwitch
                                    name={`${field}.accounts[0].universalAnalyticsTrackingParameterEnabled`}
                                />
                            </FormRow>
                            <FormRow
                                label="GA4 Property ID"
                                helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_ga4Property`}
                            >
                                <FormInput
                                    name={`${field}.accounts[0].ga4Property`}
                                    className="AdvertiserIntegrations-longField"
                                />
                            </FormRow>
                            <FormRow
                                label="Append GA4 tracking parameter"
                                helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_ga4TrackingParameterEnabled`}
                            >
                                <FormSwitch name={`${field}.accounts[0].ga4TrackingParameterEnabled`} />
                            </FormRow>
                            <FormRow
                                rowClassName="my-2"
                                label="Source-medium mappings"
                                helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_sourceMediumMappings`}
                            >
                                <LabelLink
                                    onClick={() =>
                                        showModal((toggle) => (
                                            <SourceMediumModal
                                                toggle={toggle}
                                                sourceMedium={settings?.sourceMediumMappings}
                                                onChange={(value) =>
                                                    onChange(`${field}.settings.sourceMediumMappings`, value)
                                                }
                                            />
                                        ))
                                    }
                                >
                                    {settings?.sourceMediumMappings?.length || 0} source-medium mapping(s)
                                </LabelLink>
                            </FormRow>
                            <FormRow label="Authentication" rowClassName="mt-1">
                                <GoogleExternalCredentials
                                    advertiserId={advertiserId}
                                    advertiserName={advertiserName}
                                    system={externalSystem}
                                />
                            </FormRow>
                        </>
                    )}
                    {externalSystem === 'GOOGLE_SEARCH_CONSOLE' && (
                        <>
                            <FormRow
                                label="Site urls"
                                helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_siteUrls`}
                            >
                                <FormDynamicOptionList
                                    name={`${field}.accounts[0].siteUrls`}
                                    className="AdvertiserIntegrations-longField"
                                    buttonTitle="Add url"
                                />
                            </FormRow>
                            <FormRow
                                label="Authentication"
                                rowClassName="mt-1"
                                helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_authentication`}
                            >
                                <GoogleExternalCredentials
                                    advertiserId={advertiserId}
                                    advertiserName={advertiserName}
                                    system={externalSystem}
                                />
                            </FormRow>
                        </>
                    )}
                </div>
            )}
            <hr className="my-2" />
        </>
    );
};

export default AdvertiserIntegration;
