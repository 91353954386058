import { ApolloError } from '@apollo/client';
import { isOlapReport } from 'platform/analytics/analytics.util';
import { FilterInputProps } from 'platform/analytics/modes/Analytics';
import AnalyticsInputFilter from 'platform/analytics/reportComponents/ReportFilters/AnalyticsInputFilter';
import AnalyticsOperatorFilter from 'platform/analytics/reportComponents/ReportFilters/AnalyticsOperatorFilter';
import { FilterOperator } from 'platform/common/components/FilterOperatorDropdown/FilterOperatorDropdown';
import { PopoverContainerType } from 'platform/common/components/Popover/Popover';
import {
    DimensionFilterOption,
    FilterDefinition,
    ReportFilterWithOperator,
    Template,
} from '../../analytics.types';

interface Props {
    container?: PopoverContainerType;
    filter: ReportFilterWithOperator;
    filterDefinition: FilterDefinition;
    template?: Template;
    options: DimensionFilterOption[];
    error?: ApolloError;
    disabled?: boolean;
    loading: boolean;
    refetch: () => void;
    onChange: (filter: ReportFilterWithOperator) => void;
    onRemove: () => void;
    filterInputProps?: FilterInputProps;
}

const AnalyticsFilter = ({
    container,
    filter,
    filterDefinition,
    template,
    filterInputProps,
    options,
    error,
    disabled,
    loading,
    refetch,
    onChange,
    onRemove,
}: Props) => {
    switch (filterDefinition.type) {
        case 'LIST':
            return (
                <AnalyticsOperatorFilter
                    container={container}
                    filter={filter}
                    filterDefinition={filterDefinition}
                    options={options}
                    error={error}
                    filterInputProps={filterInputProps}
                    disabled={disabled}
                    loading={loading}
                    refetch={refetch}
                    onChange={onChange}
                    onRemove={onRemove}
                />
            );
        case 'NUMBER':
        case 'TEXT': {
            const textFilterOperators: FilterOperator[] =
                !template || isOlapReport(template) ? [] : ['IS', 'IS_NOT', 'GT', 'GTE', 'LT', 'LTE'];

            return (
                <AnalyticsInputFilter
                    filter={filter}
                    filterDefinition={filterDefinition}
                    supportedOperators={textFilterOperators}
                    filterInputProps={filterInputProps}
                    disabled={disabled}
                    onChange={onChange}
                    onRemove={onRemove}
                />
            );
        }

        default:
            return null;
    }
};

export default AnalyticsFilter;
