import { Alert, Button } from 'reactstrap';
import { ReportComponentState } from 'platform/analytics/analytics.types';
import { resolveComponentMetaData } from 'platform/analytics/analytics.util';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import { useAvailableFilters } from 'platform/analytics/hooks/useAvailableFilters';
import { useCompatibleColumns } from 'platform/analytics/hooks/useCompatibleColumns';
import { useReportComponentMessage } from 'platform/analytics/hooks/useReportComponentMessage';
import { ComponentsProps } from 'platform/analytics/modes/Analytics';
import ReportChartSettings from 'platform/analytics/reportComponents/ReportChartContainer/ReportChartSettings';
import ReportLogBookSettings from 'platform/analytics/reportComponents/ReportLogBook/ReportLogBookSettings';
import ReportNote from 'platform/analytics/reportComponents/ReportNote';
import ReportPlacementSettings from 'platform/analytics/reportComponents/ReportPlacementSettings';
import ReportSummaryBarSettings from 'platform/analytics/reportComponents/ReportSummaryBar/ReportSummaryBarSettings';
import ReportTableSettings from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableSettings';
import { isDefined } from 'platform/common/common.types';
import { useReportFilterOptions } from '../ReportFilters/useReportFilterOptions';
import ReportInteractionSettings from '../ReportInteraction/ReportInteractionSettings/ReportInteractionSettings';
import ReportSpacer from '../ReportSpacer';

interface Props {
    canEdit: boolean;
    componentState: ReportComponentState;
    componentsProps?: ComponentsProps;
    height: number;
    disabled?: boolean;
    onApply: () => void;
    onCancel?: () => void;
    onChange: (componentState: ReportComponentState) => void;
}

const ReportComponentSettings = ({
    componentState,
    componentsProps,
    height,
    canEdit,
    disabled,
    onChange,
    onApply,
    onCancel,
}: Props) => {
    const { columns, templateId } = resolveComponentMetaData(componentState);
    const template = useAnalyticsTemplate(templateId);
    const { dimensionOptions, metricOptions, compatibleColumns, loading } = useCompatibleColumns({
        template,
        columns: columns.filter(isDefined),
    });
    const compatibleFilters = useAvailableFilters({ compatibleColumns, template });
    const { message, color, isComponentValid } = useReportComponentMessage({
        template,
        compatibleColumns,
        compatibleColumnsLoading: loading,
    });
    const { filterOptionsMap, resolveFilterOptions, loading: filtersLoading } = useReportFilterOptions();

    const handleApply = () => {
        if (!isComponentValid(componentState)) {
            const element = document.querySelector('.ReportComponentSettings');
            if (element) {
                element.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
            return;
        }

        onApply();
    };

    return (
        <>
            <div
                className="ReportComponentSettings p-3"
                style={{ maxWidth: 760, width: '100%', maxHeight: height, overflowY: 'auto' }}
            >
                {message && <Alert color={color}>{message}</Alert>}
                {componentState.type !== 'SPACER' && (
                    <ReportPlacementSettings componentState={componentState} onChange={onChange} />
                )}
                {(() => {
                    switch (componentState.type) {
                        case 'CHART':
                            return (
                                <ReportChartSettings
                                    componentState={componentState}
                                    template={template}
                                    compatibleFilters={compatibleFilters}
                                    dimensionOptions={dimensionOptions}
                                    metricOptions={metricOptions}
                                    filterOptionsMap={filterOptionsMap}
                                    loading={loading}
                                    filtersLoading={filtersLoading}
                                    canEdit={canEdit}
                                    onChange={onChange}
                                    onFiltersChange={resolveFilterOptions}
                                />
                            );
                        case 'TABLE':
                            return (
                                <ReportTableSettings
                                    componentState={componentState}
                                    template={template}
                                    compatibleFilters={compatibleFilters}
                                    dimensionOptions={dimensionOptions}
                                    metricOptions={metricOptions}
                                    filterOptionsMap={filterOptionsMap}
                                    loading={loading}
                                    filtersLoading={filtersLoading}
                                    canEdit={canEdit}
                                    onChange={onChange}
                                    onFiltersChange={resolveFilterOptions}
                                />
                            );
                        case 'SUMMARY_BAR':
                            return (
                                <ReportSummaryBarSettings
                                    {...componentsProps?.SUMMARY_BAR}
                                    componentState={componentState}
                                    template={template}
                                    compatibleFilters={compatibleFilters}
                                    dimensionOptions={dimensionOptions}
                                    metricOptions={metricOptions}
                                    filterOptionsMap={filterOptionsMap}
                                    loading={loading}
                                    filtersLoading={filtersLoading}
                                    canEdit={canEdit}
                                    onChange={onChange}
                                    onFiltersChange={resolveFilterOptions}
                                />
                            );
                        case 'LOG_BOOK':
                            return (
                                <ReportLogBookSettings componentState={componentState} onChange={onChange} />
                            );
                        case 'SPACER':
                            return (
                                <ReportSpacer
                                    spacer={componentState.spacer}
                                    onChange={(spacer) => onChange({ ...componentState, spacer })}
                                />
                            );
                        case 'INTERACTION':
                            return (
                                <ReportInteractionSettings
                                    componentState={componentState}
                                    onChange={onChange}
                                />
                            );
                        case 'NOTE':
                        default:
                            return null;
                    }
                })()}
                {componentState.type !== 'SPACER' && (
                    <ReportNote
                        note={componentState.note}
                        onChange={(note) => onChange({ ...componentState, note })}
                    />
                )}
            </div>
            <div className="d-flex justify-content-end px-3 pb-3">
                {onCancel && (
                    <Button color="secondary" className="me-2" onClick={onCancel}>
                        Cancel
                    </Button>
                )}
                <Button color="primary" disabled={disabled || filtersLoading} onClick={handleApply}>
                    Apply
                </Button>
            </div>
        </>
    );
};

export default ReportComponentSettings;
