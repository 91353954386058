import { useState } from 'react';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import TextEditor from 'platform/common/components/TextEditor/TextEditor';
import { updatePageContent } from '../wiki.service';
import WikiNoContent from './WikiNoContent';

interface Props {
    id: number;
    editMode: boolean;
    htmlContent: string;
}

const WikiPageContent = ({ id, htmlContent, editMode }: Props) => {
    const [value, setValue] = useState(htmlContent);

    const submit = () => {
        if (value) {
            updatePageContent(id, value);
        }
    };

    if (!editMode && !htmlContent) {
        return <WikiNoContent />;
    }

    if (editMode) {
        return (
            <TextEditor className="h-100" height="100%" value={value} onChange={setValue} onBlur={submit} />
        );
    }

    return <RawHtml className="h-100">{htmlContent}</RawHtml>;
};

export default WikiPageContent;
