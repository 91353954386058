import axiosBff from 'platform/axios/axiosBff';

export type JobStatus = 'RUNNING' | 'COMPLETED' | 'FAILED';

type DataIngestionJob = {
    name: string;
    vendor: string;
};

export type DataIngestionJobParams = {
    periodFrom?: string;
    periodTo?: string;
    periodChunkSize?: number;
    accounts?: string[];
};

export type DataIngestionJobExecution = {
    name: string;
    vendor: string;
    status: JobStatus;
    params?: DataIngestionJobParams;
    result?: { totalAccounts: number; totalRows: number };
    message?: string;
    startedOn: string;
    startedBy: string;
    endedOn?: string;
};

export const fetchDataIngestionJobs = () =>
    axiosBff.get<DataIngestionJob[]>('/ingestion/api/jobs').then((r) => r.data);

export const runDataIngestionJob = (name: string, params?: DataIngestionJobParams) =>
    axiosBff.post(`/ingestion/api/jobs/${name}/executions`, params).then((r) => r.data);

export const fetchDataIngestionJobExecutions = (params: { name?: string; since?: string; until?: string }) =>
    axiosBff
        .get<DataIngestionJobExecution[]>('/ingestion/api/jobs/executions', { params })
        .then((r) => r.data);
