import { ComponentType, FC } from 'react';
import { LinkDisplay, NavigationParams } from 'platform/analytics/analytics.types';
import { TwoFactorAuthentication } from 'platform/common/constants/twoFactorAuthentication.constant';
import { makeOptions } from 'platform/common/utils/option.util';
import { AccessType } from 'platform/userManagement/constants/access.constant';

export type Feature =
    | 'CUSTOM_DASHBOARDS'
    | 'ADVERTISERS_VIEW'
    | 'ADVERTISERS_EDIT'
    | 'CAMPAIGNS_VIEW'
    | 'CAMPAIGNS_EDIT'
    | 'CHANGELOG_VIEW'
    | 'CREATIVES_VIEW'
    | 'CREATIVES_EDIT'
    | 'ADVERTISER_TAG_VIEW'
    | 'ADVERTISER_TAG_EDIT'
    | 'SUPERUSER'
    | 'ADVERTISER_SETTINGS'
    | 'ADVERTISER_SETTINGS_EDIT'
    | 'IMPORTER'
    | 'MEDIAPLAN_VIEW'
    | 'MEDIAPLAN_EDIT'
    | 'LOGBOOK_EDIT'
    | 'LOGBOOK_VIEW'
    | 'LOGBOOK_BUDGET_SHIFTING_VIEW'
    | 'LOGBOOK_OPTIMIZATION_VIEW'
    | 'ALGORITHM_VIEW'
    | 'ALGORITHM_EDIT'
    | 'ALGORITHM_HISTORY'
    | 'ALGORITHM_PREVIEW'
    | 'OPTIMIZATION'
    | 'BUDGET'
    | 'CUSTOMER_JOURNEY_VIEW'
    | 'ANALYTICS_VIEW'
    | 'ANALYTICS_EDIT'
    | 'CENTRAL_ANALYTICS'
    | 'CUSTOM_ANALYTICS'
    | 'FINANCE_ANALYTICS'
    | 'MANAGEMENT_ANALYTICS'
    | 'ANALYTICS_INLINE_ACTIONS'
    | 'ANALYTICS_FILTERS_EDIT'
    | 'SEGMENTS_VIEW'
    | 'SEGMENTS_EDIT'
    | 'ADMIN_VIEW'
    | 'ADMIN_EDIT'
    | 'SENSITIVE_DATA'
    | 'SPEND_DATA'
    | 'USER_MANAGEMENT'
    | 'TESTING_ANALYTICS'
    | 'MONITORING_ANALYTICS'
    | 'TASK_EDIT'
    | 'TASK_BOARD_EDIT'
    | 'CHATBOT_DEFAULT_VIEW'
    | 'CHATBOT_AGENTS_VIEW'
    | 'CHATBOT_BETA_TESTING'
    | 'CHATBOT_PROMPTS_VIEW'
    | 'CHATBOT_MANAGEMENT_VIEW'
    | 'CHATBOT_MANAGEMENT_EDIT'
    | 'ANALYTICS_PERSONAL_SECTION_EDIT'
    | 'PRODUCT_ACTION_PROBABILITY'
    | 'MESSAGE_CENTER_EDIT'
    | 'CUSTOM_FOLDER_EDIT'
    | 'WIKI_VIEW'
    | 'WIKI_EDIT'
    | 'WIKI_SPACE_ACADEMY'
    | 'WIKI_SPACE_MEDIA_MANAGEMENT'
    | 'WIKI_SPACE_PLATFORM'
    | 'HELP_VIEW'
    | 'HELP_EDIT';

type NavigationItemType = 'SEPARATOR' | 'REPORT_PIN' | 'USER_REPORT_PARENT' | 'PLACEHOLDER' | 'PARENT';

export type Section =
    | 'CUSTOM_DASHBOARD'
    | 'CUSTOM_ANALYTICS'
    | 'COLLABORATION'
    | 'CENTRAL_ANALYTICS'
    | 'MANAGEMENT'
    | 'MONITORING'
    | 'FINANCE'
    | 'BUDGET'
    | 'OPTIMIZATION'
    | 'CAMPAIGNS'
    | 'TESTING'
    | 'PERSONAL';

export const SECTION_LABELS: { [key in Section]: string } = Object.freeze({
    CUSTOM_DASHBOARD: 'Custom dashboard',
    CUSTOM_ANALYTICS: 'Custom analytics',
    COLLABORATION: 'Collaboration',
    CENTRAL_ANALYTICS: 'Central Analytics',
    MANAGEMENT: 'Management',
    MONITORING: 'Monitoring',
    FINANCE: 'Finance',
    BUDGET: 'Budget',
    OPTIMIZATION: 'Optimization',
    CAMPAIGNS: 'Campaigns',
    TESTING: 'Testing',
    PERSONAL: 'Personal',
});

export const SECTION_OPTIONS = makeOptions(SECTION_LABELS);

export interface Profile {
    id: number;
    name: string;
    login: string;
    phone: string;
    company: string;
    roleId: number;
    effectiveAdvertiserAccessLevel: AccessType;
    effectiveAdvertiserIds: number[];
    accessibleChannels?: string[];
    imageUrl?: string;
    demoModeEnabled: boolean;
    demoModeMultiplier?: number;
    adminUser: boolean; // user from Admin seat
    superuser: boolean; // user with Superuser role
    seatId: number;
}

export interface Account {
    id: number;
    seatId: number;
    phone: string;
    name: string;
    impersonate: boolean;
    canImpersonate: boolean;
    features: Feature[];
    login: string;
    twoFactorAuthentication: TwoFactorAuthentication;
    needed2FA: boolean;
    platformLogoUrl?: string;
    platformAccessUrl?: string;
    companyLogoUrl?: string;
}

export interface NavigationItem {
    name: string;
    path?: string;
    systemReportKey?: string;
    redirectTo?: string;
    icon?: FC | string;
    redirectRequiresFeature?: Feature;
    requiresFeature?: Feature | Feature[];
    children?: NavigationItem[];
    isActivePath?: (pathname: string, params: NavigationParams) => boolean;
    active?: boolean;
    pin?: string;
    type?: NavigationItemType;
    isExternal?: boolean;
    section?: Section;
    display?: LinkDisplay;
    lazy?: boolean;
    admin?: boolean;
    disabled?: boolean;
    lastAccessedOn?: string;
    isAdvertiserOwned?: boolean;
    isLast?: boolean;
    showRecentChildren?: boolean;
}

export type RoutingItem = NavigationItem & {
    component: ComponentType<any>;
};

export type NavigationMode = 'default' | 'admin' | 'wiki';
