import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import qs from 'qs';
import { Advertiser } from 'platform/advertisers/advertiser.types';
import AdvertiserIntegrations from 'platform/advertisers/components/AdvertiserIntegrations/AdvertiserIntegrations';
import AdvertiserListQuery from 'platform/advertisers/queries/AdvertiserListQuery.graphql';
import { filterAdvertiserTree } from 'platform/advertisers/utils/advertiserList.util';
import { CHANGE_LOG } from 'platform/campaign/campaign.navigation';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import Search from 'platform/common/components/Search/Search';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { activeAdvertiserActions, toAdvertiserTree } from 'platform/common/ducks/activeAdvertiser.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import client from 'platform/common/utils/createApolloClient.util';
import AdvertiserFormContainer from '../AdvertiserForm/AdvertiserFormContainer';
import AdvertiserMoveFormContainer from '../AdvertiserMove/AdvertiserMoveFormContainer';
import AdvertiserWizard from '../AdvertiserWizard/AdvertiserWizard';
import './AdvertiserList.scss';

type AdvertiserTree = Advertiser & {
    children: Advertiser[];
};

const editFormAction = (advertiser: Advertiser) =>
    advertiser.type === 'AGENT'
        ? push(`/advertisers/agent/${advertiser.id}`)
        : push(`/advertisers/advertiser/${advertiser.id}`);

const newAgentAction = (parentId?: number) =>
    push({
        pathname: '/advertisers/agent/new',
        search: parentId ? qs.stringify({ parentId }) : undefined,
    });

const newAdvertiserAction = (params?: { parentId: number }) =>
    push({
        pathname: '/advertisers/advertiser/new',
        search: params && qs.stringify(params),
    });

const newAdvertiserWizardAction = (params?: { parentId: number }) =>
    push({
        pathname: '/advertisers/advertiser/wizard',
        search: params && qs.stringify(params),
    });

const getActions = (canEdit: boolean, advertiser: Advertiser, dispatch: Dispatch): PopoverDropdownItem[] => {
    if (!canEdit) {
        return [
            {
                label: 'View',
                action: () => {
                    dispatch(editFormAction(advertiser));
                },
            },
        ];
    }

    const isAgent = advertiser.type === 'AGENT';

    return [
        {
            label: 'Edit',
            action: () => {
                dispatch(editFormAction(advertiser));
            },
        },
        ...(isAgent
            ? [
                  {
                      label: 'Add advertiser (Wizard)',
                      action: () =>
                          dispatch(
                              newAdvertiserWizardAction({
                                  parentId: advertiser.id,
                              })
                          ),
                  },
                  {
                      label: 'Add advertiser (Expert)',
                      action: () => {
                          dispatch(
                              newAdvertiserAction({
                                  parentId: advertiser.id,
                              })
                          );
                      },
                  },
              ]
            : []),
        ...(!isAgent
            ? [
                  {
                      label: 'Move',
                      action: () => {
                          dispatch(push(`/advertisers/move/${advertiser.id}`));
                      },
                  },
                  {
                      label: 'Change Log',
                      action: () => {
                          dispatch(push(`${CHANGE_LOG.path}?objectTypes=Advertiser`));
                          dispatch(activeAdvertiserActions.changeAdvertisers([advertiser.id]));
                      },
                  },
              ]
            : []),
        ...(!isAgent && advertiser.state === 'ACTIVE'
            ? [
                  {
                      label: 'Advertiser Management',
                      action: () => {
                          dispatch(push('/advertiser-settings/integrations'));
                          dispatch(activeAdvertiserActions.changeAdvertisers([advertiser.id]));
                      },
                  },
              ]
            : []),
    ];
};

const AdvertiserList = () => {
    const {
        queryParams: { status, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync({
        status: ['ACTIVE'],
        searchQuery: '',
    });

    const [{ data: advertiserTree, loading }, refetch] = usePromise<AdvertiserTree[]>(
        [],
        () =>
            client
                .query<{ advertisers: Advertiser[] }>({
                    query: AdvertiserListQuery,
                    variables: { state: status },
                    fetchPolicy: 'network-only',
                })
                .then(({ data }) => toAdvertiserTree(data.advertisers)),
        [status]
    );

    const canEdit = useFeature('ADVERTISERS_EDIT');
    const canCreate = useFeature('USER_MANAGEMENT');

    const dispatch = useDispatch();
    const columns: TableColumn<Advertiser>[] = [
        {
            Header: 'Status',
            accessor: 'state',
            Cell: (props) => <StatusBadge status={props.original.state} />,
            width: 75,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 75,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Integrations',
            width: 210,
            Cell: (props) => (
                <div>
                    <AdvertiserIntegrations advertiser={props.original} />
                </div>
            ),
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: (row) => <UpdatedOn date={row.original.updatedOn} updatedBy={row.original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original }) => <InlineDropdown items={getActions(canEdit, original, dispatch)} />,
        },
    ];
    const renderTopToolbar = () => (
        <>
            <Tooltip
                tooltipVisible={!canCreate}
                renderTooltip={() => `Only users with user management access can create advertisers.`}
                placement={'bottom'}
            >
                <ButtonDropdown
                    disabled={!canCreate}
                    className="me-2"
                    items={[
                        {
                            label: 'Agent',
                            action: () => dispatch(newAgentAction()),
                        },
                        {
                            label: 'Advertiser (Wizard)',
                            action: () => dispatch(newAdvertiserWizardAction()),
                        },
                        {
                            label: 'Advertiser (Expert)',
                            action: () => dispatch(newAdvertiserAction()),
                        },
                    ]}
                >
                    Add new
                </ButtonDropdown>
            </Tooltip>
            <Search
                onSearch={(value) => setQueryParams({ searchQuery: value })}
                value={searchQuery}
                minLength={0}
                className="w-auto"
            />
        </>
    );

    const filteredList = filterAdvertiserTree(advertiserTree, searchQuery, [
        ...columns.map((columnn) => columnn.accessor),
        'updatedBy',
    ]);

    return (
        <div>
            <HeaderContainer>
                <PageHeader title="Advertisers" />
            </HeaderContainer>

            <BodyContainer helpKey="advertiser_list">
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    className="mb-3"
                    name="Status"
                    value={status}
                    options={STATUS_OPTIONS}
                    isMulti
                    onChange={(value) => setQueryParams({ status: value })}
                />
                <Routes>
                    <Route
                        path="agent/new"
                        element={
                            <AdvertiserFormContainer
                                type="AGENT"
                                redirectTo={returnUrl}
                                canEdit={canCreate}
                                afterSubmit={refetch}
                            />
                        }
                    />
                    <Route
                        path="agent/:id"
                        element={
                            <AdvertiserFormContainer
                                type="AGENT"
                                redirectTo={returnUrl}
                                canEdit={canEdit}
                                afterSubmit={refetch}
                            />
                        }
                    />
                    <Route
                        path="advertiser/new"
                        element={
                            <AdvertiserFormContainer
                                type="ADVERTISER"
                                redirectTo={returnUrl}
                                canEdit={canCreate}
                                afterSubmit={() => {
                                    refetch();
                                    dispatch(activeAdvertiserActions.reloadAdvertiserOptions());
                                }}
                            />
                        }
                    />
                    <Route
                        path="advertiser/wizard"
                        element={
                            <AdvertiserWizard
                                redirectTo={returnUrl}
                                canEdit={canCreate}
                                afterSubmit={() => {
                                    refetch();
                                    dispatch(activeAdvertiserActions.reloadAdvertiserOptions());
                                }}
                            />
                        }
                    />
                    <Route
                        path="advertiser/:id"
                        element={
                            <AdvertiserFormContainer
                                type="ADVERTISER"
                                redirectTo={returnUrl}
                                canEdit={canEdit}
                                afterSubmit={() => {
                                    refetch();
                                    dispatch(activeAdvertiserActions.reloadAdvertiserOptions());
                                }}
                            />
                        }
                    />
                    <Route
                        path="move/:id"
                        element={<AdvertiserMoveFormContainer redirectTo={returnUrl} afterSubmit={refetch} />}
                    />
                </Routes>

                <div className="mt-1">
                    <FormattedTable
                        isTree
                        topToolbar={renderTopToolbar()}
                        columns={columns}
                        loading={loading}
                        data={filteredList}
                        columnsAlignedFromIndex={4}
                        nodesExpanded={!!searchQuery}
                    />
                </div>
            </BodyContainer>
        </div>
    );
};

export default AdvertiserList;
