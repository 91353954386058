import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Button } from 'reactstrap';
import { push } from 'redux-first-history';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FileIcon from 'platform/common/components/FileLink/FileIcon';
import FileLabel from 'platform/common/components/FileLink/FileLabel';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { useLoading } from 'platform/common/hooks/useLoading';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { FileInfo, formatFileSize } from 'platform/common/utils/file.util';
import { FILE_SHARING } from '../fileSharing.navigation';
import { createSharedFile, deleteSharedFile, getSharedFiles } from '../fileSharing.service';
import { MAX_SHARED_FILE_SIZE, SharedFile } from '../fileSharing.types';
import FileSharingFormContainer from './FileSharingFormContainer';

type QueryParams = {
    status: ActiveOrArchived[];
    advertiserIds: number[];
    searchQuery: string;
};

const FileSharingList = () => {
    const {
        queryParams: { status, advertiserIds, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({ status: ['ACTIVE'], advertiserIds: [], searchQuery: '' });

    const dispatch = useDispatch();
    const [uploading, withUploading] = useLoading();
    const canEdit = useFeature('ADMIN_EDIT');
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);

    const [{ data, loading }, refetch] = usePromise([], getSharedFiles, []);

    const files = data.filter(
        (c) =>
            (!status.length || status.includes(c.status)) &&
            (!advertiserIds.length || (c.advertiserId && advertiserIds.includes(c.advertiserId))) &&
            (!searchQuery ||
                `${c.name} ${c.s3Key} ${c.description}`.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const columns: TableColumn<SharedFile>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            maxWidth: 80,
            Cell: ({ value }) => <StatusBadge status={value} />,
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 250,
            Cell: ({ value }) => (
                <div className="w-100">
                    <FileLabel filename={value} />
                </div>
            ),
        },
        {
            Header: 'Content',
            accessor: 's3Key',
            width: 320,
            Cell: ({ original: { s3Key, url } }) => (
                <a href={url} className="d-flex align-items-center cursor-pointer no-text-decoration">
                    <FileIcon filename={s3Key} />
                    <FileLabel className="ms-2" filename={s3Key} />
                </a>
            ),
        },
        {
            Header: 'Size',
            accessor: 'size',
            width: 70,
            Cell: ({ value }) => formatFileSize(value),
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Advertiser',
            accessor: 'advertiserId',
            minWidth: 150,
            Cell: ({ original: file }) => (
                <CellWithEllipsis
                    value={advertiserOptions.find((o) => o.id === file.advertiserId)?.name || '-'}
                />
            ),
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: ({ original: file }) => <UpdatedOn date={file.updatedOn} updatedBy={file.updatedBy} />,
        },
        {
            width: 50,
            sortable: false,
            Cell: ({ original: { id } }) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => dispatch(push(`${FILE_SHARING.path}/${id}`)),
                        },
                        {
                            label: 'Delete',
                            action: () => deleteSharedFile(id).then(refetch),
                            confirmation: 'Permanently delete this file?',
                        },
                    ]}
                />
            ),
        },
    ];

    const handleUpload = async (file: FileInfo) => {
        await withUploading(() => createSharedFile(file.content as Blob, file.name));
        refetch();
    };

    return (
        <>
            <HeaderContainer>
                <PageHeader title={FILE_SHARING.name} />
            </HeaderContainer>
            <BodyContainer helpKey="file_list">
                {!canEdit && <CanNotEditWarning />}
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        onChange={(value) => setQueryParams({ status: value })}
                        options={ACTIVE_OR_ARCHIVED}
                        isMulti
                    />
                    <SelectWithAddon
                        name="Advertiser"
                        value={advertiserIds}
                        options={advertiserOptions}
                        getOptionValue={(a) => a.id}
                        getOptionLabel={(a) => a.name}
                        onChange={(value) => setQueryParams({ advertiserIds: value })}
                        isMulti
                    />
                </ListFilters>
                <FormattedTable
                    topToolbar={
                        <>
                            <UploadInput
                                readFileAs="blob"
                                maxSizeInMb={MAX_SHARED_FILE_SIZE}
                                onFileUpload={handleUpload}
                                loading={uploading}
                                className="me-3"
                            >
                                <Button color="primary">Add new</Button>
                            </UploadInput>
                            <ListToolbar
                                value={searchQuery}
                                onSearch={(value) => setQueryParams({ searchQuery: value })}
                            />
                        </>
                    }
                    columns={columns}
                    data={files}
                    loading={loading}
                    defaultSorted={[{ orderBy: 'updatedOn', direction: 'DESC' }]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path=":id"
                    element={
                        <FileSharingFormContainer
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default FileSharingList;
