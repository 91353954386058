import { shallowEqual, useDispatch } from 'react-redux';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';
import { dateFilterActions, dateFilterSelectors, Periods } from 'platform/common/ducks/dateFilter.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { arrayItemUpdate } from 'platform/common/utils/array.util';
import { parseQuery } from 'platform/common/utils/url.util';
import { MediaInsertionSearchResult, Mediaplan } from 'platform/mediaplan/mediaplan.types';
import {
    DimensionFilterValues,
    DrillInstruction,
    ReportDrill,
    TableState,
    ReportFilterWithOperator,
} from '../analytics.types';
import { getAnalyticsUrl, getSystemReportUrl, removeComponentMetaData } from '../analytics.util';
import { analyticsSelectors } from '../ducks/analytics.duck';
import { getBudgetReportingPeriod } from '../hooks/useMediaplanFilter';
import { PopoverLinkItem } from './LinkPopover';

export const getDrillsForTarget = ({
    allDrills,
    row,
    target,
    dimensionFilters,
}: {
    allDrills: ReportDrill[];
    row: { [key: string]: any };
    target?: string;
    dimensionFilters?: DimensionFilterValues;
}): ReportDrill[] =>
    allDrills
        .filter((item) => item.target === target)
        .filter(({ rowValues, rowValuesExcept }) => {
            if (rowValues) {
                if (rowValuesExcept && Object.keys(row).some((k) => rowValuesExcept[k]?.includes(row[k]))) {
                    return false;
                }
                return Object.keys(rowValues).every((key) => {
                    const filterValues = dimensionFilters?.[key];
                    return (
                        rowValues[key].includes(row[key]) ||
                        // If only single filter value is present, we can assume that all report rows has the filter value
                        (filterValues?.length === 1 && rowValues[key].includes(filterValues[0]))
                    );
                });
            }

            if (rowValuesExcept) {
                return !Object.keys(rowValuesExcept).every((key) => rowValuesExcept[key].includes(row[key]));
            }

            return true;
        });

const updateFilters = (
    analyticsFilters: ReportFilterWithOperator[],
    drillFilterInstruction: DrillInstruction,
    row: { [key: string]: any }
): ReportFilterWithOperator[] => [
    ...(drillFilterInstruction.add || []).map((drillFilter) => {
        const values =
            drillFilter.values ??
            (row[drillFilter.key] && [row[drillFilter.key]]) ??
            analyticsFilters.find((analyticsFilter) => analyticsFilter.key === drillFilter.key)?.values;
        return {
            key: drillFilter.target ?? drillFilter.key,
            values,
            resolvedValues: values,
        };
    }),
    ...analyticsFilters.filter(
        (analyticsFilter) =>
            !(drillFilterInstruction.add || []).some((drillFilter) => drillFilter.key === analyticsFilter.key)
    ),
];

const updateDimensions = (dimensions: string[], drill: ReportDrill) => [
    ...dimensions.filter(
        (dimension) => !(drill.dimensions?.remove ?? []).some((dim) => dim.key === dimension)
    ),
    ...(drill.dimensions?.add ?? []).filter((dim) => !dimensions.includes(dim.key)).map((dim) => dim.key),
];

const getDrillPeriods = ({
    filters,
    periods,
    drill,
    mediaPlans,
    mediaInsertions,
}: {
    filters: ReportFilterWithOperator[];
    periods: Periods;
    drill: ReportDrill;
    mediaPlans: Mediaplan[];
    mediaInsertions: MediaInsertionSearchResult[];
}): Periods => {
    const budgetReportPeriod =
        drill.target === 'media_insertion_name' &&
        getBudgetReportingPeriod({ filters, periods, mediaPlans, mediaInsertions });

    return budgetReportPeriod
        ? { primaryFrom: budgetReportPeriod.from, primaryTo: budgetReportPeriod.to }
        : periods;
};

export const useDrillCell = (drills: ReportDrill[], row: Record<string, any>, componentId?: number) => {
    const dispatch = useDispatch();
    const location = useTypedSelector((state) => state.router.location);
    const analyticsSettings = useTypedSelector(analyticsSelectors.settings);
    const periods = useTypedSelector(dateFilterSelectors.periods, shallowEqual);
    const componentState = useTypedSelector(analyticsSelectors.reportComponentById(componentId));
    const { mediaPlans, mediaInsertions } = useAnalyticsMetadata();
    const { accessibleReports } = useCustomReports();

    const [{ data: drillLinks }] = usePromise(
        [],
        () =>
            Promise.all(
                componentState?.type !== 'TABLE'
                    ? []
                    : drills.map((drill) =>
                          drill.systemReportKey ? getReportDrill(drill) : getTableDrill(drill, componentState)
                      )
            ),
        []
    );

    const getReportDrill = ({ systemReportKey, filter }: ReportDrill) => {
        const analyticsFilters = analyticsSettings.filters.map((f) => ({
            ...f,
            values: row[f.key] ? [row[f.key]] : f.values,
        }));
        const drillFilters = filter ? updateFilters(analyticsFilters, filter, row) : analyticsFilters;
        return getSystemReportUrl(accessibleReports, systemReportKey!, {
            filters: drillFilters.filter((f) => f.values),
        });
    };

    const getTableDrill = (drill: ReportDrill, tableState: TableState) => {
        const activePreset = tableState.presets[tableState.activePresetIndex];

        const newFilters = drill.filter
            ? updateFilters(
                  activePreset.templateId === 'customer_journey'
                      ? analyticsSettings.filters.filter(
                            (analyticsFilter) => analyticsFilter.key !== 'advertiser_id'
                        )
                      : analyticsSettings.filters,
                  drill.filter,
                  row
              )
            : analyticsSettings.filters;

        const newTableState: TableState = {
            ...tableState,
            pageNumber: 0,
            sort: drill.sorts ?? tableState.sort,
            presets: arrayItemUpdate(tableState.presets, tableState.activePresetIndex, {
                ...activePreset,
                dimensions: updateDimensions(activePreset.dimensions, drill),
                metrics: drill.metrics ?? activePreset.metrics,
            }),
        };

        return getAnalyticsUrl(parseQuery(location?.search ?? '').reportId, {
            filters: newFilters.filter((f) => f.values),
            components: [removeComponentMetaData(newTableState)],
        });
    };

    const clickDrill = (drill: ReportDrill) => {
        if (drill.target !== 'media_insertion_name') {
            return;
        }

        const filters = drill.filter
            ? updateFilters(analyticsSettings.filters, drill.filter, row)
            : analyticsSettings.filters;

        const drillPeriods = getDrillPeriods({
            drill,
            periods,
            filters,
            mediaPlans,
            mediaInsertions,
        });

        dispatch(dateFilterActions.changePeriods(drillPeriods));
    };

    const items = drills.reduce<PopoverLinkItem[]>((acc, drill, index) => {
        if (drill.isPrimary) {
            return acc;
        }

        return [
            ...acc,
            {
                label: drill.name || '',
                link: drillLinks[index] || '/',
                icon: drill.icon,
                onClick: () => clickDrill(drills[index]),
            },
        ];
    }, []);

    return {
        drillLinks,
        items,
    };
};
