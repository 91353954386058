import { isEqual } from 'lodash-es';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import analyticsCustomReportsReducer from 'platform/analytics/ducks/analyticsCustomReports.duck';
import { combineReducersWithRoot } from 'platform/common/combineReducersWithRoot';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { RootState } from 'platform/rootState.type';
import { AnalyticsSettings, AnalyticsState, ReportFilter } from '../analytics.types';
import analyticsMetadataReducer from './analyticsMeta.duck';
import analyticsSettingsReducer from './analyticsSettings.duck';

const analyticsReducer = combineReducersWithRoot<AnalyticsState>({
    meta: analyticsMetadataReducer,
    settings: analyticsSettingsReducer,
    customReports: analyticsCustomReportsReducer,
});

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const ownFilters = (state: RootState): ReportFilter[] => {
    const filtersWithOperator = analyticsSelectors.filtersWithOperator(state);
    return filtersWithOperator.map(({ key, values, resolvedValues }) => ({
        key,
        values: resolvedValues ?? values,
    }));
};

export const analyticsSelectors = {
    root: (state: RootState): AnalyticsState => state.analyticsReducer,
    settings: (state: RootState): AnalyticsSettings => state.analyticsReducer.settings,
    customReports: (state: RootState) => state.analyticsReducer.customReports,
    compareType: (state: RootState) =>
        analyticsSelectors.settings(state).compareWith ?? dateFilterSelectors.periods(state).secondaryPreset,
    gridColumns: (state: RootState) => analyticsSelectors.settings(state).gridColumns,
    filtersWithOperator: (state: RootState) => analyticsSelectors.settings(state).filters,
    ownFilters,
    debugMode: (state: RootState) => analyticsSelectors.settings(state).debugMode,
    filters: createDeepEqualSelector(ownFilters, activeAdvertiserSelectors.ids, (filters, advertiserIds) => [
        ...filters,
        { key: 'advertiser_id', values: advertiserIds },
    ]),
    nonEmptyFilters: createDeepEqualSelector(
        (state: RootState) => ownFilters(state).filter((f) => f.values.length),
        activeAdvertiserSelectors.ids,
        (filters, advertiserIds) => [...filters, { key: 'advertiser_id', values: advertiserIds }]
    ),
    meta: (state: RootState) => {
        const meta = state.analyticsReducer.meta;
        if (!meta) {
            throw new Error('Analytics meta not ready, selector accessed from a wrong state');
        }
        return meta;
    },
    singleAdvertiserMode: (state: RootState) => activeAdvertiserSelectors.ids(state).length === 1,
    reportComponents: (state: RootState) => analyticsSelectors.settings(state).components,
    reportComponentById: (id?: number) => (state: RootState) =>
        analyticsSelectors.settings(state).components.find((c) => c.id === id),
    nextId: (state: RootState) =>
        Math.max(...analyticsSelectors.settings(state).components.map((c) => c.id ?? 0), 0) + 1,
    interactionComponents: (state: RootState) =>
        state.analyticsReducer.settings.components.filter((c) => c.type === 'INTERACTION'),
};

export default analyticsReducer;
