import { useDispatch } from 'react-redux';
import { intersection, isEmpty } from 'lodash-es';
import { AnalyticsUrlSettings, NavigationParams, ReportFilter } from 'platform/analytics/analytics.types';
import { updateTableComponent } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import {
    analyticsSettingsActions,
    DEFAULT_ANALYTICS_SETTINGS,
} from 'platform/analytics/ducks/analyticsSettings.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { activeAdvertiserSelectors, AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterActions } from 'platform/common/ducks/dateFilter.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { parseQuery } from 'platform/common/utils/url.util';
import { fetchCustomReport } from 'platform/customReports/customReport.service';
import { CustomReportWithSettings } from 'platform/customReports/customReport.types';
import { logReportAccess } from '../analytics.service';
import { addUrlFilters } from './useApplyUrlSettings';
import { useCustomReports } from './useCustomReports';

interface Props {
    urlSettings: AnalyticsUrlSettings;
    advertiserId?: number;
    algorithmId?: number;
    reportId?: number;
    onRedirect: () => void;
    toggleEditMode: (isEdit: boolean) => void;
    onAdvertisersChange: (advertisers: AdvertiserOption[]) => void;
}

const addOrRestrictFilter = (filters: ReportFilter[], { key, values }: ReportFilter) => {
    if (!filters.find((f) => f.key === key)) {
        return [...filters, { key, values }];
    }
    return filters.map((filter) => {
        if (filter.key === key) {
            const restrictedValues = intersection(filter.values as any[], values);
            return { ...filter, values: restrictedValues?.length ? restrictedValues : values };
        }
        return filter;
    });
};

export const useFetchReport = ({
    algorithmId,
    advertiserId,
    reportId,
    urlSettings,
    onRedirect,
    toggleEditMode,
    onAdvertisersChange,
}: Props) => {
    const { internalLinkId }: NavigationParams = parseQuery(location.search);
    const { definitions, hasDefaultMetrics } = useAnalyticsMetadata();
    const activeAdvertiser = useTypedSelector(activeAdvertiserSelectors.activeAdvertiser);
    const activeAdvertiserIds = useTypedSelector(activeAdvertiserSelectors.ids);
    const accessibleAdvertisers = useTypedSelector(activeAdvertiserSelectors.options);
    const profile = useTypedSelector(authSelectors.ready.profile);
    const previousAnalyticSettings = useTypedSelector(analyticsSelectors.settings);
    const accessibleAdvertiserIds = accessibleAdvertisers.map((a) => a.id);
    const dispatch = useDispatch();
    const { accessibleReports } = useCustomReports();

    const getFilters = ({ analyticsSettings }: CustomReportWithSettings) => {
        let filters = analyticsSettings?.filters ?? [];
        if (profile.accessibleChannels?.length) {
            filters = addOrRestrictFilter(filters, { key: 'channel', values: profile.accessibleChannels });
        }
        if (algorithmId) {
            filters = addOrRestrictFilter(filters, { key: 'algorithm_id', values: [algorithmId] });
        }
        return filters.filter((f) => definitions.filters.find((fd) => fd.target === f.key));
    };

    const canAccessByAdvertiser = (report: CustomReportWithSettings) =>
        !!report.advertiserIds?.length &&
        accessibleAdvertiserIds.some((i) => report.advertiserIds?.includes(i));

    const canAccessBySeat = ({ seatIds }: CustomReportWithSettings) =>
        seatIds.includes(profile.seatId) || accessibleAdvertisers.some((a) => seatIds.includes(a.seatId!));

    const canAccessLink = ({ section, seatIds }: CustomReportWithSettings) =>
        !section && (!seatIds.length || seatIds.includes(profile.seatId));

    const canAccessPersonalReport = ({ ownerUserId, section }: CustomReportWithSettings) =>
        section === 'PERSONAL' && profile.id === ownerUserId;

    const canAccess = (report: CustomReportWithSettings): boolean =>
        profile.adminUser ||
        canAccessByAdvertiser(report) ||
        canAccessBySeat(report) ||
        canAccessLink(report) ||
        canAccessPersonalReport(report);

    const getReportAdvertiserIds = (customReport: CustomReportWithSettings): number[] | undefined => {
        const canChangeAdvertisers = (ids: number[]) =>
            ids.every((id) => accessibleAdvertiserIds.includes(id)) &&
            (!activeAdvertiserIds.every((id) => ids.includes(id)) ||
                (activeAdvertiserIds.every((id) => ids.includes(id)) &&
                    activeAdvertiserIds.length !== ids.length));

        if (advertiserId && canChangeAdvertisers([advertiserId])) {
            return [advertiserId];
        }

        if (customReport.advertiserIds?.length && canChangeAdvertisers(customReport.advertiserIds)) {
            return customReport.advertiserIds;
        }

        const userSeatIds = profile.adminUser ? [profile.seatId, activeAdvertiser.seatId] : [profile.seatId];
        const hasUserAccess = customReport.seatIds.some((seatId) => userSeatIds.includes(seatId));
        if (!hasUserAccess) {
            const id = accessibleAdvertisers.find((a) => customReport.seatIds.includes(a.seatId!))?.id;
            return id ? [id] : undefined;
        }

        return undefined;
    };

    // HACK: if report id has stayed the same, we imply that user is loading it with a changed advertiser
    const advertiserWasChanged = (report: CustomReportWithSettings) =>
        previousAnalyticSettings.customReportId === report.id;

    const handleAdvertisersChange = (newAdvertiserIds: number[]) => {
        const newAdvertisers = accessibleAdvertisers.filter((a) => newAdvertiserIds.includes(a.id));
        if (!newAdvertisers.length) {
            return;
        }
        onAdvertisersChange(newAdvertisers);
    };

    const handleEmptyReportId = () => {
        if (isEmpty(urlSettings)) {
            toggleEditMode(true);
        }
        const { dimensions, ...restSettings } = urlSettings;
        dispatch(
            analyticsSettingsActions.changeSettings({
                ...DEFAULT_ANALYTICS_SETTINGS,
                ...(!previousAnalyticSettings.customReportId && previousAnalyticSettings),
                ...restSettings,
            })
        );
    };

    const shouldIncludeDefaultMetrics = (includeDefaultMetrics?: boolean) => {
        // some reports have only part of default metrics added
        // if we return includeDefaultMetrics as false they will be removed
        // to prevent that we return undefined and skip default metrics check
        if (!includeDefaultMetrics && hasDefaultMetrics) {
            return undefined;
        }

        return !!includeDefaultMetrics && hasDefaultMetrics;
    };

    const setReportData = (customReport: CustomReportWithSettings) => {
        const { periods, analyticsSettings } = customReport;

        if (periods) {
            dispatch(dateFilterActions.changePeriods(periods));
        }

        const reportChanges =
            customReport.section === 'CENTRAL_ANALYTICS' && advertiserWasChanged(customReport)
                ? previousAnalyticSettings
                : {};

        if (analyticsSettings) {
            const filters = addUrlFilters(getFilters(customReport), urlSettings.filters);

            dispatch(
                analyticsSettingsActions.changeSettings({
                    ...analyticsSettings,
                    components: analyticsSettings.components.map((component) =>
                        updateTableComponent(component, urlSettings)
                    ),
                    filters,
                    customReportId: customReport.id,
                    section: customReport.section,
                    ...reportChanges,
                    includeDefaultMetrics: shouldIncludeDefaultMetrics(
                        analyticsSettings.includeDefaultMetrics
                    ),
                })
            );
        }
    };

    const [{ data: report, loading: isReportLoading }, refetchReport] = usePromise(
        undefined,
        async () => {
            if (!reportId) {
                handleEmptyReportId();
                return undefined;
            }
            toggleEditMode(false);

            if (activeAdvertiser.id) logReportAccess(internalLinkId || reportId, activeAdvertiser.id);

            const customReport = await fetchCustomReport(reportId);

            if (
                !canAccess(customReport) ||
                (advertiserWasChanged(customReport) &&
                    !accessibleReports.some((r) => r.id === customReport.id))
            ) {
                onRedirect();
                return undefined;
            }

            const reportAdvertiserIds = getReportAdvertiserIds(customReport);
            if (reportAdvertiserIds && reportAdvertiserIds.length) {
                handleAdvertisersChange(reportAdvertiserIds);
                return undefined;
            }

            setReportData(customReport);

            return customReport;
        },
        [reportId, advertiserId]
    );

    return {
        report,
        isReportLoading,
        refetchReport,
    };
};
