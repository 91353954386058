import { useEffect, useMemo, useReducer } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Alert, Modal } from 'reactstrap';
import { AxiosResponse } from 'axios';
import ReportAiSummaryContainer, {
    ReportAiSummaryContainerProps,
} from 'platform/analytics/reportComponents/ReportTableContainer/ReportAiSummaryContainer/ReportAiSummaryContainer';
import { isDefined } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import { useModal } from 'platform/common/components/Modal/Modal';
import { usePolling } from 'platform/common/hooks/usePolling';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { datesOverlap } from 'platform/common/utils/date.util';
import { User } from 'platform/userManagement/types/user.type';
import BoardChangesSidePanel from '../ChangeLog/BoardChangesSidePanel';
import DeeplyTaskCreator from '../DeeplyTaskCreator/DeeplyTaskCreator';
import Timeline from '../Timeline/Timeline';
import { boardReducer } from '../reducers/boardReducer';
import {
    Task,
    TaskBoardChange,
    TaskBoardState,
    TaskCreationData,
    TaskData,
    TaskLaneData,
    TaskLocation,
    TaskStatus,
} from '../task.types';
import { findTask, isParentLane } from '../task.util';
import KanbanBoard from './KanbanBoard';
import KanbanCardModal from './KanbanCardModal';
import KanbanFilters, { KanbanFilterValues } from './KanbanFilters';
import KanbanPageHeader from './KanbanPageHeader';
import KanbanTaskCopyModal from './KanbanTaskCopyModal';
import './Kanban.scss';

export const matchesDateFilter = (
    date: [string, string],
    task: Task,
    includeTasksWithoutDates?: boolean
): boolean => {
    const { startDate, dueDate } = task;

    if (includeTasksWithoutDates && !startDate && !dueDate) {
        return true;
    }

    const taskHasStartDate = !!startDate;
    const taskHasDueDate = !!dueDate;

    if (taskHasStartDate && taskHasDueDate) {
        return datesOverlap(startDate, dueDate, date[0], date[1]);
    }
    if (taskHasStartDate && !taskHasDueDate) {
        return datesOverlap(startDate, date[1], date[0], date[1]);
    }
    if (!taskHasStartDate && taskHasDueDate) {
        return datesOverlap(date[0], dueDate, date[0], date[1]);
    }

    return false;
};

const matchesStatusFilter = (statusFilter: TaskStatus[], task: Task) =>
    !statusFilter.length || statusFilter.includes(task.status);

export const matchesLabelFilter = (labelFilter: string[], operator: 'OR' | 'AND', task: Task) =>
    !labelFilter.length ||
    (operator === 'AND'
        ? labelFilter.every((key) => task.labelKeys?.includes(key))
        : task.labelKeys?.some((key) => labelFilter.includes(key)));

export const matchesAssigneeFilter = (
    assigneeFilter: number[],
    operator: 'OR' | 'AND',
    task: Task
): boolean =>
    !assigneeFilter.length ||
    (operator === 'AND'
        ? assigneeFilter.every((key) => task.assigneeIds?.includes(key))
        : task.assigneeIds?.some((key) => assigneeFilter.includes(key))) ||
    (task.subtasks?.some((t) => matchesAssigneeFilter(assigneeFilter, operator, t)) ?? false);

export const matchesSearchFilter = (search: string, task: Task) =>
    !search || task.name.toLowerCase().includes(search.toLowerCase());

type Props = {
    initialState: TaskBoardState;
    userOptions: User[];
    canEditBoard: boolean;
    onLabelUpdate: (key: string, name: string) => Promise<TaskBoardChange[]>;
    onLaneAdd: (position: number, data: TaskLaneData) => Promise<TaskBoardChange[]>;
    onLaneUpdate: (laneId: number, data: TaskLaneData) => Promise<TaskBoardChange[]>;
    onLaneMove: (laneId: number, position: number) => Promise<TaskBoardChange[]>;
    onLaneDelete: (laneId: number) => Promise<TaskBoardChange[]>;
    onTaskAdd: (location: TaskLocation, data: TaskCreationData) => Promise<TaskBoardChange[]>;
    onTaskUpdate: (taskId: number, data: Partial<TaskData>) => Promise<TaskBoardChange[]>;
    onTaskWatchChange: (taskId: number, watch: boolean) => Promise<TaskBoardChange[]>;
    onTaskMove: (taskId: number, location: TaskLocation) => Promise<TaskBoardChange[]>;
    onTaskDelete: (taskId: number) => Promise<TaskBoardChange[]>;
    onAttachmentUpload: (taskId: number, name: string, content: Blob) => Promise<TaskBoardChange[]>;
    onAttachmentDelete: (taskId: number, attachmentId: number) => Promise<TaskBoardChange[]>;
    onAttachmentDownload: (taskId: number, attachmentId: number) => Promise<AxiosResponse<Blob>>;
    onLoadNewChanges: (sinceVersion: number) => Promise<TaskBoardChange[]>;
};

type QueryParams = {
    openTaskId?: number;
    search: string;
    assignees: {
        operator: 'OR' | 'AND';
        values: number[];
    };
    labels: {
        operator: 'OR' | 'AND';
        values: string[];
    };
    status: TaskStatus[];
    lanes: number[];
    date: string[];
    includeTasksWithoutDates: boolean;
    unfilteredTasks: number[];
};

const KanbanPage = ({
    initialState,
    userOptions,
    canEditBoard,
    onLabelUpdate,
    onLaneAdd,
    onLaneUpdate,
    onLaneMove,
    onLaneDelete,
    onTaskAdd,
    onTaskUpdate,
    onTaskWatchChange,
    onTaskMove,
    onTaskDelete,
    onAttachmentUpload,
    onAttachmentDelete,
    onAttachmentDownload,
    onLoadNewChanges,
}: Props) => {
    const [board, dispatch] = useReducer(boardReducer, initialState);
    const { showModal } = useModal();
    const {
        queryParams: {
            openTaskId,
            search,
            assignees,
            labels,
            status,
            lanes,
            includeTasksWithoutDates,
            date: [dateFrom = '', dateTo = ''],
            unfilteredTasks,
        },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        openTaskId: undefined,
        search: '',
        assignees: {
            operator: 'OR',
            values: [],
        },
        labels: {
            operator: 'OR',
            values: [],
        },
        status: ['ACTIVE'],
        lanes: [],
        date: [],
        includeTasksWithoutDates: true,
        unfilteredTasks: [],
    });

    const filters = useMemo<KanbanFilterValues>(
        () => ({
            search,
            assignees,
            labels,
            status,
            lanes,
            includeTasksWithoutDates,
            date: [dateFrom, dateTo],
        }),
        [search, assignees, labels, includeTasksWithoutDates, status, lanes, dateTo]
    );

    useEffect(() => {
        setQueryParams({ unfilteredTasks: [] });
    }, [filters]);

    useEffect(() => {
        // switch to another board's state, which happens when navigating between boards
        dispatch({ type: 'RESET', state: initialState });
    }, [initialState]);

    useEffect(() => {
        if (openTaskId) {
            const found = findTask(board.lanes, openTaskId);
            const lane = found?.lane;
            const parentTaskId = found?.parentTaskId;
            const foundParent = parentTaskId ? findTask(board.lanes, parentTaskId) : undefined;

            showModal((toggle) =>
                found && lane ? (
                    <KanbanCardModal
                        board={board}
                        task={found.task}
                        parentTask={foundParent?.task}
                        lane={lane}
                        canRenameLabels={canEditBoard}
                        userOptions={userOptions}
                        onTaskAdd={handleTaskAdd}
                        onTaskUpdate={handleTaskUpdate}
                        onTaskWatchChange={handleTaskWatchChange}
                        onTaskMove={handleTaskMove}
                        onTaskDelete={handleTaskDelete}
                        onAttachmentUpload={handleAttachmentUpload}
                        onAttachmentDelete={handleAttachmentDelete}
                        onAttachmentDownload={onAttachmentDownload}
                        onLabelUpdate={handleLabelUpdate}
                        onTaskCopy={onTaskCopy}
                        onTaskOpen={(taskId) => {
                            toggle();
                            setQueryParams({ openTaskId: taskId });
                        }}
                        onClose={() => {
                            toggle();
                            setQueryParams({ openTaskId: undefined });
                        }}
                    />
                ) : (
                    <Modal isOpen toggle={toggle}>
                        <Alert className="w-100" color="warning">
                            Could not find task with ID {openTaskId} in this board.
                        </Alert>
                    </Modal>
                )
            );
        }
    }, [openTaskId, board.lanes]);

    useEffect(() => {
        if (board) {
            document.addEventListener('keydown', showDeeplyCreateDialog);
        }

        return () => {
            document.removeEventListener('keydown', showDeeplyCreateDialog);
        };
    }, [board]);

    const showDeeplyCreateDialog = (event: KeyboardEvent) => {
        if (event.altKey && event.code === 'KeyC') {
            showModal((toggle) => (
                <DeeplyTaskCreator
                    userOptions={userOptions}
                    labelNames={board?.labelNames ?? {}}
                    lanes={board?.lanes ?? []}
                    toggle={toggle}
                    onTaskOpen={(value) => setQueryParams({ openTaskId: value })}
                    onTaskAdd={handleTaskAdd}
                />
            ));
        }
    };

    usePolling(async () => (await onLoadNewChanges(board.version)).forEach(dispatch), { interval: 10_000 });

    const applyChanges = async (request: () => Promise<TaskBoardChange[]>) => {
        const changes = await request();
        changes.forEach(dispatch);
        return changes;
    };

    const handleLaneAdd = async (position: number, data: TaskLaneData) => {
        await applyChanges(() => onLaneAdd(position, data));
    };

    const handleLaneUpdate = async (laneId: number, data: TaskLaneData) => {
        dispatch({ type: 'LANE_SYNC_PENDING', laneId });
        await applyChanges(() => onLaneUpdate(laneId, data));
        dispatch({ type: 'LANE_SYNC_COMPLETED', laneId });
    };

    const handleLaneMove = async (laneId: number, position: number) => {
        // for better UX, first we move the lane visually and then wait for the request to complete
        dispatch({ type: 'LANE_MOVE_PENDING', laneId, position });
        await applyChanges(() => onLaneMove(laneId, position));
        dispatch({ type: 'LANE_SYNC_COMPLETED', laneId });
    };

    const handleLaneDelete = async (laneId: number) => {
        dispatch({ type: 'LANE_SYNC_PENDING', laneId });
        await applyChanges(() => onLaneDelete(laneId));
        dispatch({ type: 'LANE_SYNC_COMPLETED', laneId });
    };

    const handleTaskAdd = async (location: TaskLocation, data: TaskCreationData) => {
        // when board is filtered by labels, assign the first label to new tasks (but not subtasks):
        const creationData: TaskCreationData =
            filters.labels.values.length && isParentLane(location)
                ? { ...data, labelKeys: [filters.labels.values[0]] }
                : data;

        const changes = await applyChanges(() => onTaskAdd(location, creationData));
        const taskId = changes.map((c) => (c.type === 'TASK_ADDED' ? c.taskId : undefined)).find(isDefined);
        if (taskId) {
            setQueryParams({ unfilteredTasks: [...unfilteredTasks, taskId] });
        }
        return changes;
    };

    const handleTaskUpdate = async (taskId: number, data: Partial<TaskData>) => {
        dispatch({ type: 'TASK_SYNC_PENDING', taskId });
        const changes = await applyChanges(() => onTaskUpdate(taskId, data));
        dispatch({ type: 'TASK_SYNC_COMPLETED', taskId });
        return changes;
    };

    const handleTaskWatchChange = async (taskId: number, watch: boolean) => {
        dispatch({ type: 'TASK_SYNC_PENDING', taskId });
        const changes = await applyChanges(() => onTaskWatchChange(taskId, watch));
        dispatch({ type: 'TASK_SYNC_COMPLETED', taskId });
        return changes;
    };

    const handleTaskMove = async (taskId: number, location: TaskLocation) => {
        // for better UX, first we move the task visually and then wait for the request to complete
        dispatch({ type: 'TASK_MOVE_PENDING', taskId, location });
        const changes = await applyChanges(() => onTaskMove(taskId, location));
        dispatch({ type: 'TASK_SYNC_COMPLETED', taskId });
        return changes;
    };

    const handleTaskDelete = async (taskId: number) => {
        dispatch({ type: 'TASK_SYNC_PENDING', taskId });
        const changes = await applyChanges(() => onTaskDelete(taskId));
        dispatch({ type: 'TASK_SYNC_COMPLETED', taskId });
        return changes;
    };

    const handleAttachmentUpload = async (taskId: number, name: string, content: Blob) => {
        dispatch({ type: 'TASK_SYNC_PENDING', taskId });
        const changes = await applyChanges(() => onAttachmentUpload(taskId, name, content));
        dispatch({ type: 'TASK_SYNC_COMPLETED', taskId });
        return changes;
    };

    const handleAttachmentDelete = async (taskId: number, attachmentId: number) => {
        dispatch({ type: 'TASK_SYNC_PENDING', taskId });
        const changes = await applyChanges(() => onAttachmentDelete(taskId, attachmentId));
        dispatch({ type: 'TASK_SYNC_COMPLETED', taskId });
        return changes;
    };

    const handleLabelUpdate = async (key: string, name: string) => {
        await applyChanges(() => onLabelUpdate(key, name));
    };

    const onTaskCopy = (task: Task) =>
        showModal((toggle) => (
            <KanbanTaskCopyModal
                toggle={toggle}
                task={task}
                onTaskAdd={handleTaskAdd}
                onTaskUpdate={handleTaskUpdate}
                lanes={board.lanes.map((op) => ({ label: op.name, value: op.id }))}
            />
        ));

    const matchesFilters = (task: Task) =>
        unfilteredTasks.includes(task.id) ||
        (matchesSearchFilter(search, task) &&
            matchesAssigneeFilter(assignees.values, assignees.operator, task) &&
            matchesLabelFilter(labels.values, labels.operator, task) &&
            matchesStatusFilter(status, task) &&
            matchesDateFilter([dateFrom, dateTo] as [string, string], task, includeTasksWithoutDates));

    const filteredLanes = board.lanes
        .filter((lane) => !lanes.length || lanes.includes(lane.id))
        .map((lane) => ({
            ...lane,
            tasks: lane.tasks.map((task) => (matchesFilters(task) ? task : { ...task, hidden: true })),
        }));

    return (
        <>
            <ReportAiSummaryContainer showContainer containerClassName="m-3">
                {(summaryProps: ReportAiSummaryContainerProps) => (
                    <>
                        <HeaderContainer>
                            <KanbanPageHeader
                                board={board}
                                canEdit={canEditBoard}
                                filters={filters}
                                onChangeFilters={setQueryParams}
                                summaryProps={summaryProps}
                                filteredLanes={filteredLanes}
                                userOptions={userOptions}
                            />
                        </HeaderContainer>
                        <KanbanFilters
                            labelNames={board.labelNames ?? {}}
                            lanes={board.lanes}
                            userOptions={userOptions}
                            filters={filters}
                            onChange={setQueryParams}
                        />
                        {board.status === 'ARCHIVED' && (
                            <Alert color="warning" className="mb-3">
                                This task board has been archived.
                            </Alert>
                        )}
                    </>
                )}
            </ReportAiSummaryContainer>

            <BodyContainer className="overflow-auto">
                <Routes>
                    <Route
                        path="timeline"
                        element={
                            <Timeline
                                labelNames={board.labelNames ?? {}}
                                tasks={filteredLanes.flatMap((lane) =>
                                    lane.tasks.map((task) => ({ ...task, laneName: lane.name }))
                                )}
                                filters={filters}
                                onTaskOpen={(value) => setQueryParams({ openTaskId: value })}
                            />
                        }
                    />
                    <Route path="change-log" element={<BoardChangesSidePanel />} />
                    <Route
                        path=""
                        element={
                            <KanbanBoard
                                board={board}
                                filteredLanes={filteredLanes}
                                userOptions={userOptions}
                                canEditBoard={canEditBoard}
                                filters={filters}
                                onStatusFilterChange={(value) => setQueryParams({ status: value })}
                                onLaneAdd={handleLaneAdd}
                                onLaneUpdate={handleLaneUpdate}
                                onLaneMove={handleLaneMove}
                                onLaneDelete={handleLaneDelete}
                                onTaskAdd={handleTaskAdd}
                                onTaskUpdate={handleTaskUpdate}
                                onTaskMove={handleTaskMove}
                                onTaskDelete={handleTaskDelete}
                                onTaskOpen={(value) => setQueryParams({ openTaskId: value })}
                                onTaskCopy={onTaskCopy}
                                onAttachmentDownload={onAttachmentDownload}
                                onTaskWatchChange={handleTaskWatchChange}
                            />
                        }
                    />
                </Routes>
            </BodyContainer>
        </>
    );
};

export default KanbanPage;
