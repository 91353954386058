import { useState, useEffect } from 'react';
import { ActionMeta } from 'react-select';
import { ApolloError } from '@apollo/client';
import { isEqual, sortBy } from 'lodash-es';
import { DimensionFilterOption, ReportFilterWithOperator } from 'platform/analytics/analytics.types';
import ErrorIndicator from 'platform/common/components/Errors/ErrorIndicator';
import FilterRemoveIcon from 'platform/common/components/FilterRemoveIcon/FilterRemoveIcon';
import FilterValuesPopover from 'platform/common/components/FilterValuesPopover/FilterValuesPopover';
import { PopoverContainerType } from 'platform/common/components/Popover/Popover';
import SelectOptionWithStatus from 'platform/common/components/Select/SelectOptionWithStatus';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import FilterOperatorDropdown, {
    FilterOperator,
} from '../../../common/components/FilterOperatorDropdown/FilterOperatorDropdown';

interface Props {
    name: string;
    filter: ReportFilterWithOperator;
    operators: FilterOperator[];
    options: DimensionFilterOption[];
    error?: ApolloError;
    multi: boolean;
    disabled?: boolean;
    loading: boolean;
    refetch: () => void;
    onChange: (filter: ReportFilterWithOperator) => void;
    onRemove: () => void;
    container?: PopoverContainerType;
}

const AnalyticsInFilter = ({
    name,
    filter,
    operators,
    options,
    error,
    multi,
    loading,
    disabled,
    refetch,
    onChange,
    onRemove,
    container,
}: Props) => {
    const [filterValues, setFilterValues] = useState(filter.values);

    useEffect(() => {
        if (isEqual(filterValues, filter.values)) {
            return;
        }

        setFilterValues(filter.values);
    }, [filter.values]);

    const handleSelectChange = (newValue: any, actionMeta?: ActionMeta<any>) => {
        const newValues = Array.isArray(newValue) ? newValue : [newValue];
        setFilterValues(newValues);
        if (multi && actionMeta?.action !== 'clear') {
            return;
        }
        onChange({
            ...filter,
            values: newValues,
        });
    };

    const handleOperatorChange = (newFilter: ReportFilterWithOperator) => {
        const values = ['IS', 'IS_NOT'].includes(newFilter.operator!)
            ? newFilter.values
            : options
                  .filter((option) => newFilter.values.includes(option.value))
                  .map((option) => option.label);

        onChange({
            ...newFilter,
            values,
        });
    };

    return (
        <SelectWithAddon
            className="position-relative"
            menuStyle={{ minWidth: 400 }}
            name={name}
            keepSearchInputAfterSelect
            secondAddon={
                <FilterOperatorDropdown
                    operator={filter.operator ?? 'IS'}
                    supportedOperators={operators}
                    container={container}
                    onChange={(operator) =>
                        handleOperatorChange({
                            ...filter,
                            operator,
                            values: filterValues,
                        })
                    }
                />
            }
            value={filterValues}
            options={sortBy(options, [(o) => o.status, (o) => o.label])}
            formatOptionLabel={SelectOptionWithStatus}
            isDisabled={disabled}
            isLoading={loading}
            isClearable
            isMulti={multi}
            collapseMoreValues
            onChange={handleSelectChange}
            onBlur={() => {
                if (!multi) {
                    return;
                }
                onChange({
                    ...filter,
                    values: filterValues,
                });
            }}
            endAddon={
                <>
                    <ErrorIndicator
                        className="w-100 d-flex justify-content-center rounded"
                        loading={loading}
                        error={error}
                        onRetry={refetch}
                    />
                    {!!filter.resolvedValues?.length && !!filter.operator && filter.operator !== 'IS' && (
                        <FilterValuesPopover values={filter.resolvedValues} options={options} />
                    )}
                    {!disabled && (
                        <FilterRemoveIcon
                            style={error && { background: 'rgba(247,225,226,.7)' }}
                            onClick={onRemove}
                        />
                    )}
                </>
            }
        />
    );
};

export default AnalyticsInFilter;
