import axiosBff from 'platform/axios/axiosBff';
import { FILE_UPLOAD_HEADERS, fileUploadData } from 'platform/common/utils/file.util';
import { WikiAttachment, WikiPageData, WikiPageForm, WikiSpace } from './wiki.types';

const PAGES_URL = '/wiki/api/pages';
const FILES_URL = '/wiki/api/files';

export const fetchPages = (space: WikiSpace) =>
    axiosBff.get<WikiPageData[]>(`${PAGES_URL}`, { params: { space } }).then((r) => r.data);

export const fetchPage = (id: number | string) =>
    axiosBff.get<WikiPageData>(`${PAGES_URL}/${id}`).then((r) => r.data);

export const createPage = (pageData: WikiPageForm) =>
    axiosBff.post<WikiPageData>(PAGES_URL, pageData).then((r) => r.data);

export const fetchPageContent = (id: number | string): Promise<string> =>
    axiosBff.get<string>(`${PAGES_URL}/${id}/content`).then((r) => r.data);

export const updatePageTitle = (id: number | string, title: string) =>
    axiosBff.put<WikiPageData>(`${PAGES_URL}/${id}/title`, { title }).then((r) => r.data);

export const updatePageContent = (id: number | string, html: string) =>
    axiosBff
        .put<WikiPageData>(`${PAGES_URL}/${id}/content`, html, { headers: { 'Content-Type': 'text/html' } })
        .then((r) => r.data);

export const updatePagePosition = (
    id: number | string,
    order: number | string,
    parentId?: number | string | null
) => axiosBff.put<void>(`${PAGES_URL}/${id}/position`, { order, parentId }).then((r) => r.data);

export const fetchAttachments = (pageId: number | string) =>
    axiosBff.get<WikiAttachment[]>(FILES_URL, { params: { pageId } }).then((r) => r.data);

export const uploadAttachment = (pageId: number | string, name: string, content: Blob) =>
    axiosBff
        .post<void>(FILES_URL, fileUploadData(content, name), { params: { pageId }, ...FILE_UPLOAD_HEADERS })
        .then((r) => r.data);

export const deleteAttachment = (id: number | string) =>
    axiosBff.delete<void>(`${FILES_URL}/${id}`).then((r) => r.data);

export const downloadAttachment = (id: number | string) =>
    axiosBff.get<Blob>(`${FILES_URL}/${id}/content`, {
        responseType: 'blob',
    });
