import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import classNames from 'classnames';
import { maxBy } from 'lodash-es';
import { SummaryBarState } from 'platform/analytics/analytics.types';
import { findColumn } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useDefaultMetrics } from 'platform/analytics/hooks/useDefaultMetrics';
import CustomFilterIndicator from 'platform/analytics/reportComponents/CustomFilterIndicator';
import ReportSummaryBarChart from 'platform/analytics/reportComponents/ReportSummaryBar/ReportSummaryBarChart';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import InlineSelectTree from 'platform/common/components/InlineSelectTree/InlineSelectTree';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { arrayItemUpdate } from 'platform/common/utils/array.util';
import StatisticWithDelta from 'platform/dashboard/components/StatisticWithDelta/StatisticWithDelta';
import ReportPivotController from '../ReportPivotController/ReportPivotController';
import { useReportSummaryBar } from './useReportSummaryBar';
import './ReportSummaryBar.scss';

export interface SummaryBarProps {
    canChangeColumn?: boolean;
    isChartEnabled?: boolean;
}

type Props = {
    componentState: SummaryBarState;
    editMode: boolean;
    className?: string;
} & SummaryBarProps;

const ReportSummaryBar = ({ componentState, canChangeColumn = true, isChartEnabled = true }: Props) => {
    const isMobile = useTypedSelector(layoutSelectors.isMobile);
    const { showCompareValues } = useTypedSelector(analyticsSelectors.settings);

    const {
        settings,
        template,
        report,
        loading,
        error,
        warning,
        rows,
        refetchReport,
        getOptions,
        compatibleMetrics,
        compareReport,
    } = useReportSummaryBar(componentState);

    useDefaultMetrics({ componentState, compatibleMetrics, compatibleMetricsLoading: loading });

    const dispatch = useDispatch();
    const changeComponentState = (newState: SummaryBarState) =>
        dispatch(analyticsSettingsActions.changeComponentState(newState));

    const handleColumnDefinitionChange = (metric: string, index: number, rowIndex: number) => {
        changeComponentState({
            ...componentState,
            activeColumn: metric,
            rows: arrayItemUpdate(
                componentState.rows,
                rowIndex,
                arrayItemUpdate(componentState.rows[rowIndex], index, metric)
            ),
        });
    };

    if (loading) {
        return <Placeholder minHeight="290px" />;
    }

    const [firstRow = [], ...restRows] = rows;
    const activeColumn = canChangeColumn ? componentState.activeColumn || firstRow[0]?.key : undefined;
    const chartVisible =
        componentState.isChartVisible && isChartEnabled && activeColumn && !!findColumn(report, activeColumn);

    if (!template) {
        return null;
    }

    const colCount = maxBy(rows, (row) => row.length)?.length || 1;
    const getCellStyles = (cellKey: string, last: boolean): CSSProperties => ({
        borderBottom: `3px solid ${cellKey === activeColumn ? '#1A90D7' : 'transparent'}`,
        width: `${(1 / colCount) * 100}%`,
        borderRight: last ? 'none' : '1px solid #cbd0d9',
    });

    return (
        <div className="d-flex flex-column flex-grow-1 position-relative">
            {!!error && <ErrorMessage error={error} onRetry={refetchReport} />}
            {!!warning && (
                <Alert className="mb-0 rounded-0" color="warning">
                    {warning}
                </Alert>
            )}
            {!!report && (
                <>
                    {!!settings.customFilters?.length && (
                        <CustomFilterIndicator
                            filters={settings.customFilters}
                            style={{ position: 'absolute', top: '6px' }}
                        />
                    )}
                    <div className="ReportSummaryBar--container">
                        <div className="ReportSummaryBar--row SelectableDashboardSummary d-flex flex-column flex-md-row flex-grow-1">
                            {firstRow.map(({ key, name, value, tooltip, delta }, index) => (
                                <div
                                    key={`${key}${index}`}
                                    className="ReportSummaryBar--cell p-2 cursor-pointer"
                                    style={getCellStyles(key, index === firstRow.length - 1)}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() =>
                                        changeComponentState({ ...componentState, activeColumn: key })
                                    }
                                >
                                    <StatisticWithDelta
                                        name={name}
                                        columnKey={key}
                                        value={value}
                                        delta={delta}
                                        tooltip={tooltip}
                                        valueClassName="fw-bold"
                                        showCompareValue={showCompareValues}
                                    >
                                        {canChangeColumn && (
                                            <InlineSelectTree
                                                value={key}
                                                options={getOptions(key)}
                                                renderTooltip={() => 'Change metric'}
                                                onChange={(metric) =>
                                                    handleColumnDefinitionChange(metric, index, 0)
                                                }
                                            />
                                        )}
                                    </StatisticWithDelta>
                                </div>
                            ))}
                        </div>
                        {(restRows || []).map((row, i) => {
                            if (!row.length) {
                                return null;
                            }

                            return (
                                <div
                                    key={i}
                                    className="ReportSummaryBar--row SelectableDashboardSummary d-flex flex-column flex-md-row border-top"
                                >
                                    {row.map(({ key, name, value, delta, tooltip }, index) => (
                                        <div
                                            key={`${key}${index}`}
                                            className="ReportSummaryBar--cell p-2 cursor-pointer"
                                            style={getCellStyles(key, index === firstRow.length - 1)}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() =>
                                                changeComponentState({
                                                    ...componentState,
                                                    activeColumn: key,
                                                })
                                            }
                                        >
                                            <StatisticWithDelta
                                                name={name}
                                                columnKey={key}
                                                value={value}
                                                delta={delta}
                                                tooltip={tooltip}
                                                valueClassName={classNames('fw-bold', {
                                                    'font-md': !isMobile,
                                                })}
                                                showCompareValue={showCompareValues}
                                            >
                                                {canChangeColumn && (
                                                    <InlineSelectTree
                                                        value={key}
                                                        options={getOptions(key)}
                                                        onChange={(metric) =>
                                                            handleColumnDefinitionChange(metric, index, 1)
                                                        }
                                                    />
                                                )}
                                            </StatisticWithDelta>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                        <ReportTracingTips report={report} compareReport={compareReport} />
                    </div>
                    {chartVisible && !loading && (
                        <div className="border-top">
                            <div className="d-flex justify-content-end mt-3 mb-0 me-3">
                                <ReportPivotController
                                    onChange={(option) =>
                                        changeComponentState({
                                            ...componentState,
                                            activeDimension: option.value,
                                        })
                                    }
                                    value={componentState?.activeDimension ?? 'date'}
                                />
                            </div>
                            <ReportSummaryBarChart
                                settings={settings}
                                column={activeColumn}
                                dimension={componentState?.activeDimension ?? 'date'}
                                showChartValues={componentState.showChartValues}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ReportSummaryBar;
