import { useCallback, useEffect, useState } from 'react';
import { uniqueId } from 'lodash-es';
import { ErrorDetails } from '../error.types';

type State<T> = {
    loading: boolean;
    data: T;
    error?: ErrorDetails;
};

export const usePromise = <T>(
    initialState: T,
    promiseFn: () => Promise<T>,
    deps: any[] = [],
    resetDataOnRefetch: boolean = true
): [State<T>, () => void, (data: T) => void] => {
    const [state, setState] = useState<State<T> & { requestId: string }>({
        loading: true,
        data: initialState,
        requestId: '',
    });

    const fetchData = useCallback(async () => {
        const requestId = uniqueId();
        setState((s) => ({
            ...s,
            requestId,
            loading: true,
            data: resetDataOnRefetch ? initialState : s.data,
        }));

        try {
            const response = await promiseFn();
            setState((s) => {
                if (s.requestId !== requestId) {
                    return s;
                }
                return { requestId: s.requestId, loading: false, data: response };
            });
        } catch (error) {
            setState((s) => {
                if (s.requestId !== requestId) {
                    return s;
                }
                return { requestId: s.requestId, loading: false, error, data: s.data };
            });
        }
    }, deps);

    const resetData = useCallback(
        (data) => setState({ loading: false, data, requestId: '', error: undefined }),
        deps
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [{ loading: state.loading, data: state.data, error: state.error }, fetchData, resetData];
};
