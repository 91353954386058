import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ChartOptions, TooltipItem } from 'chart.js';
import { isNil, sum } from 'lodash-es';
import { CHART_PREVIEW_HEIGHT } from 'platform/analytics/analytics.constants';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { BarState, StackBarState, Template } from 'platform/analytics/analytics.types';
import { exportChartData, exportedFilename, findColumn } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import ReportChartTitle from 'platform/analytics/reportComponents/ReportChartContainer/ReportChartTitle';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { useReport } from 'platform/analytics/reportComponents/useReport';
import ChartEmptyView from 'platform/common/components/ChartEmptyView/ChartEmptyView';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import InformationModal from 'platform/common/components/InformationModal/InformationModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { DATA_TYPES, typeOf } from 'platform/common/dataTypes';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import { CHART_COLORS, getChartColors } from 'platform/common/utils/color.util';
import '../ReportChart.scss';

const TOP_ROWS_COUNT = 10;

const MAX_LABEL_LENGTH = 40;

export type ReportBarChartProps = {
    template: Template;
    reportName: string;
    componentState: BarState | StackBarState;
    MAX_LABEL_LENGTH?: number;
};

const ReportBarChart = ({ template, reportName, componentState }: ReportBarChartProps) => {
    const {
        title,
        showOtherValues = true,
        sortBy,
        customFilters,
        customDateRange,
        metrics,
        dimensions,
    } = componentState;
    const dimension = dimensions[0];
    const { includeVatRate, modelOptIn } = useSelector(analyticsSelectors.settings);
    const isMobile = useSelector(layoutSelectors.isMobile);
    const { showModal } = useModal();
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const settings = useMemo<ReportSettings>(
        () => ({
            dimensions: [dimension],
            metrics,
            customFilters,
            customDateRange,
            templateId: template.id,
            sort:
                sortBy === 'METRIC'
                    ? [{ orderBy: metrics[0], direction: 'DESC' }]
                    : [{ orderBy: dimension, direction: 'ASC' }],
            includeVatRate,
            modelOptIn,
        }),
        [sortBy, dimension, metrics, customFilters, customDateRange, template.id, includeVatRate, modelOptIn]
    );
    const { loading, report, error, refetchReport } = useReport({
        settings,
        type: template.type,
    });

    if (loading) return <Placeholder minHeight="290px" />;
    if (error) return <ErrorMessage error={error} onRetry={refetchReport} />;

    const metricCells = report?.header.filter((item) => metrics.includes(item.key));
    const dimensionCell = findColumn(report, dimension);

    if (!report || !metricCells?.length || !dimensionCell) {
        return <ChartEmptyView />;
    }

    const { format: formatDimension } = typeOf(findColumn(report, dimension));
    const multipleMetrics = metrics.length > 1;
    const rows = report.rows.filter((row) => row[dimension]);
    const topRows = rows.slice(0, TOP_ROWS_COUNT);
    const otherRows = showOtherValues ? rows.slice(TOP_ROWS_COUNT, rows.length) : [];
    const other = otherRows.length
        ? metrics.reduce<Record<string, string | number>>(
              (result, metric) => ({
                  ...result,
                  [metric]: sum(otherRows.map((row) => row[metric])),
              }),
              { label: 'Other' }
          )
        : undefined;

    const labels = [
        ...topRows.map((row) => row[dimension]).map(formatDimension),
        other?.label as string,
    ].filter(Boolean);

    const data = {
        labels,
        datasets: metrics.map((metric, index) => ({
            data: [...topRows.map((row) => row[metric]), other?.[metric]],
            backgroundColor: multipleMetrics
                ? CHART_COLORS[index]
                : getChartColors(other ? topRows.length + 1 : topRows.length),
            label: metricCells.find((m) => m.key === metric)?.name,
        })),
    };

    const options: ChartOptions<'bar'> = {
        layout: labels.some((x) => x.length > MAX_LABEL_LENGTH) ? { padding: { bottom: 16 } } : undefined,
        scales: {
            x: {
                ticks: {
                    callback: (tick: number) => {
                        let value = '';

                        if (labels && labels[tick]) {
                            value = labels[tick];
                        }

                        if (typeof value !== 'string' || value.length <= MAX_LABEL_LENGTH) {
                            return value;
                        }

                        let middle = Math.floor(value.length / 2);
                        const before = value.lastIndexOf(' ', middle);
                        const after = value.indexOf(' ', middle + 1);

                        if (middle - before < after - middle) {
                            middle = before;
                        } else {
                            middle = after;
                        }

                        const s1 = value.substring(0, middle);
                        const s2 = value.substring(middle + 1);

                        return [s1, s2];
                    },
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        animation: false,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                mode: 'nearest',
                intersect: false,
                callbacks: {
                    label(tooltipItem: TooltipItem<'bar'>) {
                        if (isNil(tooltipItem.dataIndex) || isNil(tooltipItem.datasetIndex)) return '-';
                        const metric = metrics[tooltipItem.datasetIndex];
                        const { format } = typeOf(findColumn(report, metric), DATA_TYPES.FLOAT);
                        const value = tooltipItem.raw;
                        const total = rows.reduce((acc, row) => acc + row[metric], 0);
                        return `${tooltipItem.dataset.label}: ${format(value)} (${DATA_TYPES.P100.format(
                            typeof value === 'number' && (value / total) * 100
                        )})`;
                    },
                },
            },
        },
    };

    const titleText = title || `${metricCells.map((m) => m.name).join(', ')} by ${dimensionCell.name}`;

    return (
        <>
            <ReportChartTitle title={titleText} template={template} componentState={componentState}>
                <div className="ReportChart-buttonContainer ms-1">
                    <Button
                        className="ReportChart-button"
                        title="View in modal"
                        onClick={() =>
                            showModal((toggle) => (
                                <InformationModal
                                    isOpen
                                    toggle={toggle}
                                    title={titleText}
                                    style={{ maxWidth: '80%' }}
                                >
                                    <Bar height={CHART_PREVIEW_HEIGHT} data={data} options={options} />
                                </InformationModal>
                            ))
                        }
                    >
                        <i className="fal fa-expand" />
                    </Button>
                    {!isMobile && (
                        <Button
                            className="ReportChart-button"
                            title="Download chart data"
                            onClick={exportChartData(
                                metrics,
                                dimension,
                                report,
                                exportedFilename(reportName, titleText, periods)
                            )}
                        >
                            <i className="fa fa-download" />
                        </Button>
                    )}
                </div>
            </ReportChartTitle>
            <div className="d-flex align-items-center h-100 p-3">
                <div className="w-100">
                    <Bar data={data} options={options} height={285} />
                </div>
            </div>
            <ReportTracingTips report={report} />
        </>
    );
};

export default ReportBarChart;
