import { useState } from 'react';
import { Input } from 'reactstrap';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import useWikiPages from '../useWikiPages';
import { updatePageTitle } from '../wiki.service';

interface Props {
    id: number;
    title: string;
    editMode: boolean;
}

const WikiPageHeader = ({ id, title, editMode }: Props) => {
    const { reloadPages } = useWikiPages();
    const [value, setValue] = useState(title);

    const handleBlur = async () => {
        await updatePageTitle(id, value);
        reloadPages();
    };

    return (
        <PageHeader
            title={
                editMode ? (
                    <Input value={value} onChange={(e) => setValue(e.target.value)} onBlur={handleBlur} />
                ) : (
                    title
                )
            }
        />
    );
};

export default WikiPageHeader;
