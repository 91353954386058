import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { FormikErrors } from 'formik';
import { keyBy } from 'lodash-es';
import { AnalyticsSettings } from 'platform/analytics/analytics.types';
import { analyticsSettingsToApi, periodsToApi } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { DEFAULT_ANALYTICS_SETTINGS } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';
import ReportSaveForm, {
    ReportSaveFormModel,
} from 'platform/analytics/reportComponents/ReportSave/ReportSaveForm';
import { Profile, Section } from 'platform/app/app.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import {
    createCustomReport,
    fetchCustomReport,
    updateCustomReport,
} from 'platform/customReports/customReport.service';
import { CustomReportWithSettings, CustomReport } from 'platform/customReports/customReport.types';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';
import { fetchSeats } from 'platform/userManagement/services/userManagement.service';
import { getSectionOptions } from './ReportSave';

type Props = {
    reportId?: number;
    seatIds?: number[];
    name?: string;
    usedAsTemplate?: boolean;
    isLinked?: boolean;
    section?: Section;
    toggle: () => void;
    analyticsSettings?: AnalyticsSettings;
};

const INITIAL_REPORT: CustomReportWithSettings = {
    name: '',
    type: 'STANDARD',
    advertiserIds: [],
    userIds: [],
    assigneeIds: [],
    status: 'ACTIVE',
    systemReportKey: undefined,
    seatIds: [],
    usedAsTemplate: false,
    analyticsSettings: DEFAULT_ANALYTICS_SETTINGS,
};

const validateForm = ({ report }: ReportSaveFormModel): FormikErrors<ReportSaveFormModel> => {
    if (report.usedAsTemplate && (report.seatIds.length !== 1 || report.seatIds[0] !== ADMIN_SEAT_ID)) {
        return { report: { usedAsTemplate: 'Templates can only be assigned to Admin seat' } };
    }
    return {};
};

const findFilter = (report: CustomReportWithSettings, key: string) =>
    report.analyticsSettings?.filters?.find((f) => f.key === key);

const resolveOperationType = (
    report: CustomReport,
    profile: Profile,
    isAdmin: Boolean,
    isLinked: boolean
) => {
    if (isAdmin) {
        if (isLinked) {
            return 'CREATE';
        }

        if (report.section === 'PERSONAL' && report.ownerUserId !== profile.id) {
            return 'CREATE';
        }

        return report.id ? 'UPDATE' : 'CREATE';
    }

    return report.id && report.section === 'PERSONAL' ? 'UPDATE' : 'CREATE';
};

const ReportSaveModal = ({
    seatIds = [],
    reportId,
    section,
    name,
    isLinked = false,
    usedAsTemplate = false,
    toggle,
    analyticsSettings,
}: Props) => {
    const currentAnalyticsSettings = useSelector(analyticsSelectors.settings);
    const settings = analyticsSettings ?? currentAnalyticsSettings;
    const canEditCustomFolders = useFeature('CUSTOM_FOLDER_EDIT');
    const hasPersonal = useFeature('ANALYTICS_PERSONAL_SECTION_EDIT');
    const profile = useTypedSelector(authSelectors.ready.profile);
    const advertiser = useTypedSelector(activeAdvertiserSelectors.activeAdvertiser);
    const advertiserIds = useTypedSelector(activeAdvertiserSelectors.ids);
    const { definitions } = useAnalyticsMetadata();
    const { reloadCustomReports } = useCustomReports();
    const periods = useTypedSelector(dateFilterSelectors.periods);
    const isAdmin = useTypedSelector(authSelectors.isAdmin);
    const filterDefinitions = keyBy(definitions.filters, (fd) => fd.target);
    const resolveReportSection = (report: CustomReport) => {
        if (profile?.roleId !== 100 && report.section === 'CENTRAL_ANALYTICS') {
            return undefined;
        }

        if (isAdmin) {
            return report.section;
        }

        const sectionOptions = getSectionOptions(canEditCustomFolders, hasPersonal);
        return sectionOptions.length === 1 ? sectionOptions[0].value : undefined;
    };

    const open = async (): Promise<ReportSaveFormModel> => {
        const [report, seats] = await Promise.all([
            reportId
                ? fetchCustomReport(reportId)
                : {
                      ...INITIAL_REPORT,
                      section,
                      usedAsTemplate,
                      seatIds,
                  },
            fetchSeats({ states: ['ACTIVE'] }),
        ]);

        return {
            report: {
                ...report,
                section: resolveReportSection(report),
                name: name ?? report.name,
            },
            seatOptions: seats.map((seat) => ({ value: seat.id, label: seat.name })),
            filters: settings.filters.map(({ key, values }) => ({
                key,
                values,
                definition: filterDefinitions[key],
            })),
            operationType: resolveOperationType(report, profile, isAdmin, isLinked),
            shouldSaveDateRange: !!report.periods,
            useReportAdvertiser: !!report.advertiserIds?.length,
            shouldSaveCampaigns: !!findFilter(report, 'campaign_id'),
            shouldSaveStrategies: !!findFilter(report, 'strategy_id'),
            usedAsSystemReport: !!report.systemReportKey,
            maintenanceMode: !!settings?.maintenanceMode,
        };
    };

    const submit = async (model: ReportSaveFormModel) => {
        const isUpdate = model.operationType === 'UPDATE';
        const isPersonal = model.report.section === 'PERSONAL';
        const { filters, ...restSettings } = analyticsSettingsToApi(settings);
        const settingsApiModel: AnalyticsSettings = {
            ...restSettings,
            maintenanceMode: model?.maintenanceMode,
            filters: filters.map((f) => {
                const emptyFilter = { key: f.key, values: [] };
                switch (f.key) {
                    case 'campaign_id':
                        return model.shouldSaveCampaigns ? f : emptyFilter;
                    case 'strategy_id':
                        return model.shouldSaveStrategies ? f : emptyFilter;
                    default:
                        return filterDefinitions[f.key]?.canBeSaved ? f : emptyFilter;
                }
            }),
        };

        const saveReport = isUpdate ? updateCustomReport : createCustomReport;
        await saveReport({
            ...model.report,
            id: isUpdate ? model.report.id : undefined,
            seatIds: isPersonal || !isAdmin ? [profile.seatId] : model.report.seatIds,
            folderId:
                isUpdate && settings.section === model.report.section ? model.report.folderId : undefined,
            systemReportKey: isPersonal ? undefined : model.report.systemReportKey,
            advertiserIds: model.useReportAdvertiser ? advertiserIds : [],
            periods: model.shouldSaveDateRange ? periodsToApi(periods) : undefined,
            analyticsSettings: settingsApiModel,
        });

        await reloadCustomReports(advertiser.seatId);
        toggle();
    };

    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '50em' }} className="UserReportSaveModal">
            <ModalHeader onClose={toggle}>Pin report</ModalHeader>
            <ModalBody>
                <FormContainer
                    helpKey="report_save_form"
                    onOpen={open}
                    onSubmit={submit}
                    validate={validateForm}
                >
                    {(props) => <ReportSaveForm {...props.formikProps} isLinked={isLinked} toggle={toggle} />}
                </FormContainer>
            </ModalBody>
        </Modal>
    );
};

export default ReportSaveModal;
