import { ReactNode } from 'react';
import { pick } from 'lodash-es';
import { PopoverContainerType } from 'platform/common/components/Popover/Popover';
import PopoverDropdown from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import './FilterOperatorDropdown.scss';

export type FilterOperator =
    | 'IS'
    | 'IS_NOT'
    | 'CONTAINS'
    | 'CONTAINS_NOT'
    | 'GT'
    | 'GTE'
    | 'LT'
    | 'LTE'
    | 'OR'
    | 'AND';

type Props<T> = {
    operator: T;
    supportedOperators: T[];
    onChange: (operator: T) => void;
    container?: PopoverContainerType;
};

const OPERATOR_LABELS: Record<FilterOperator, ReactNode> = {
    IS: <span className="font-xl">=</span>,
    IS_NOT: <span className="font-xl">≠</span>,
    CONTAINS: <span className="font-xs">contains</span>,
    CONTAINS_NOT: <span className="font-xs">does not contain</span>,
    GT: <span className="font-xl">&gt;</span>,
    GTE: <span className="font-xl">≥</span>,
    LT: <span className="font-xl">&lt;</span>,
    LTE: <span className="font-xl">≤</span>,
    OR: <span className="font-xs">any</span>,
    AND: <span className="font-xs">all</span>,
};

const FilterOperatorDropdown = <T extends FilterOperator>({
    operator,
    supportedOperators,
    onChange,
    container,
}: Props<T>) => (
    <PopoverDropdown
        togglerClassName="FilterOperatorDropdown-toggler"
        popoverClassName="FilterOperatorDropdown-popover"
        placement="bottom"
        container={container}
        toggler={OPERATOR_LABELS[operator]}
        items={Object.entries(pick(OPERATOR_LABELS, supportedOperators)).map(([value, label]) => ({
            label,
            action: () => onChange(value as T),
        }))}
    />
);

export default FilterOperatorDropdown;
