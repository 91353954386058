import { arrayItemInsert, arrayItemMove } from 'platform/common/utils/array.util';
import {
    AnalyticsMode,
    AnalyticsSettings,
    FilterDefinition,
    ReportComponentState,
    ReportFilterWithOperator,
} from '../analytics.types';

const updateFilterValue = (
    filters: ReportFilterWithOperator[],
    filter: ReportFilterWithOperator
): ReportFilterWithOperator[] => {
    const index = filters.findIndex((f) => f.key === filter.key);
    if (index > -1) {
        return [...filters.slice(0, index), filter, ...filters.slice(index + 1, filters.length)];
    }
    return [...filters, filter];
};

const CHANGE_GRID_COLUMNS = 'CHANGE_GRID_COLUMNS';
const SAVE_FILTER = 'SAVE_FILTER';
const CHANGE_COMPONENT_STATES = 'CHANGE_COMPONENT_STATES';
const CHANGE_COMPATIBLE_FILTERS = 'CHANGE_COMPATIBLE_FILTERS';
const ADD_COMPONENT_TO_INDEX = 'ADD_COMPONENT_TO_INDEX';
const ADD_COMPONENT = 'ADD_COMPONENT';
const MOVE_COMPONENT = 'MOVE_COMPONENT';
const REMOVE_COMPONENT = 'REMOVE_COMPONENT';
const REMOVE_FILTER = 'REMOVE_FILTER';
const CHANGE_INCLUDE_VAT_RAT = 'CHANGE_INCLUDE_VAT_RAT';
const CHANGE_MODEL_OPT_IN = 'CHANGE_MODEL_OPT_IN';
const CHANGE_SETTINGS = 'CHANGE_SETTINGS';
const CHANGE_MODE = 'CHANGE_MODE';
const CHANGE_FILTERS = 'CHANGE_FILTERS';

type ChangeGridColumns = {
    type: typeof CHANGE_GRID_COLUMNS;
    payload: number;
};

type SaveFilter = {
    type: typeof SAVE_FILTER;
    payload: ReportFilterWithOperator;
};

type RemoveFilter = {
    type: typeof REMOVE_FILTER;
    payload: ReportFilterWithOperator;
};

type ChangeComponentStates = {
    type: typeof CHANGE_COMPONENT_STATES;
    payload: ReportComponentState[];
};

type ChangeCompatibleFilters = {
    type: typeof CHANGE_COMPATIBLE_FILTERS;
    payload: {
        componentId: number;
        compatibleFilters: FilterDefinition[];
    };
};

type AddComponent = {
    type: typeof ADD_COMPONENT;
    payload: ReportComponentState;
};

type AddComponentToIndex = {
    type: typeof ADD_COMPONENT_TO_INDEX;
    payload: { component: ReportComponentState; index: number };
};

type MoveComponent = {
    type: typeof MOVE_COMPONENT;
    payload: { from: number; to: number };
};

type RemoveComponent = {
    type: typeof REMOVE_COMPONENT;
    payload: number;
};

type ChangeIncludeVatRat = {
    type: typeof CHANGE_INCLUDE_VAT_RAT;
    payload: boolean;
};

type ChangeModelOptIn = {
    type: typeof CHANGE_MODEL_OPT_IN;
    payload: boolean;
};

type ChangeMode = {
    type: typeof CHANGE_MODE;
    payload: AnalyticsMode;
};

type ChangeSettings = {
    type: typeof CHANGE_SETTINGS;
    payload: AnalyticsSettings;
};

type ChangeFilters = {
    type: typeof CHANGE_FILTERS;
    payload: ReportFilterWithOperator[];
};

type AnalyticsSettingsActions =
    | ChangeGridColumns
    | SaveFilter
    | RemoveFilter
    | ChangeComponentStates
    | ChangeCompatibleFilters
    | AddComponent
    | MoveComponent
    | RemoveComponent
    | ChangeIncludeVatRat
    | ChangeModelOptIn
    | ChangeMode
    | ChangeSettings
    | ChangeFilters
    | AddComponentToIndex;

export const DEFAULT_ANALYTICS_SETTINGS: AnalyticsSettings = {
    gridColumns: 12,
    compareWith: undefined,
    mode: 'DEFAULT',
    datePickerMode: 'DEFAULT',
    filters: [],
    components: [],
    includeVatRate: true,
    modelOptIn: false,
    debugMode: false,
    showCompareValues: false,
    inlineEditing: false,
    includeDefaultMetrics: undefined,
    maintenanceMode: false,
};

const analyticsSettingsReducer = (
    state = DEFAULT_ANALYTICS_SETTINGS,
    action: AnalyticsSettingsActions
): AnalyticsSettings => {
    switch (action.type) {
        case CHANGE_GRID_COLUMNS: {
            return {
                ...state,
                gridColumns: action.payload,
                components: state.components.map((c) =>
                    c.placement.width <= action.payload
                        ? c
                        : { ...c, placement: { ...c.placement, width: action.payload } }
                ),
            };
        }
        case SAVE_FILTER: {
            return { ...state, filters: updateFilterValue(state.filters, action.payload) };
        }
        case REMOVE_FILTER: {
            return { ...state, filters: state.filters.filter((filter) => filter.key !== action.payload.key) };
        }
        case CHANGE_COMPONENT_STATES: {
            return {
                ...state,
                components: state.components.map((c) => action.payload.find((s) => s.id === c.id) ?? c),
            };
        }
        case CHANGE_COMPATIBLE_FILTERS: {
            return {
                ...state,
                components: state.components.map((c) =>
                    c.id === action.payload.componentId
                        ? {
                              ...c,
                              compatibleFilters: action.payload.compatibleFilters,
                              compatibleFiltersLoaded: true,
                          }
                        : c
                ),
            };
        }
        case ADD_COMPONENT_TO_INDEX: {
            return {
                ...state,
                components: arrayItemInsert(state.components, action.payload.component, action.payload.index),
            };
        }
        case ADD_COMPONENT: {
            return {
                ...state,
                components: [...state.components, action.payload],
            };
        }
        case MOVE_COMPONENT: {
            return {
                ...state,
                components: arrayItemMove(state.components, action.payload.from, action.payload.to),
            };
        }
        case REMOVE_COMPONENT: {
            return {
                ...state,
                components: state.components.filter((c) => c.id !== action.payload),
            };
        }
        case CHANGE_INCLUDE_VAT_RAT: {
            return {
                ...state,
                includeVatRate: action.payload,
            };
        }
        case CHANGE_MODEL_OPT_IN: {
            return {
                ...state,
                modelOptIn: action.payload,
            };
        }
        case CHANGE_SETTINGS: {
            return {
                ...action.payload,
            };
        }
        case CHANGE_MODE: {
            return {
                ...state,
                mode: action.payload,
            };
        }
        case CHANGE_FILTERS: {
            return {
                ...state,
                filters: action.payload,
            };
        }
        default:
            return state;
    }
};

export const analyticsSettingsActions = {
    changeGridColumns: (payload: number): ChangeGridColumns => ({ type: CHANGE_GRID_COLUMNS, payload }),
    saveFilter: (payload: ReportFilterWithOperator): SaveFilter => ({ type: SAVE_FILTER, payload }),
    removeFilter: (payload: ReportFilterWithOperator): RemoveFilter => ({ type: REMOVE_FILTER, payload }),
    changeComponentState: (state: ReportComponentState): ChangeComponentStates => ({
        type: CHANGE_COMPONENT_STATES,
        payload: [state],
    }),
    changeComponentStates: (payload: ReportComponentState[]): ChangeComponentStates => ({
        type: CHANGE_COMPONENT_STATES,
        payload,
    }),
    changeCompatibleFilters: (payload: ChangeCompatibleFilters['payload']): ChangeCompatibleFilters => ({
        type: CHANGE_COMPATIBLE_FILTERS,
        payload,
    }),
    addComponent: (payload: ReportComponentState): AddComponent => ({
        type: ADD_COMPONENT,
        payload,
    }),
    addComponentToIndex: (payload: ReportComponentState, index: number): AddComponentToIndex => ({
        type: ADD_COMPONENT_TO_INDEX,
        payload: { component: payload, index },
    }),
    moveComponent: (payload: { from: number; to: number }): MoveComponent => ({
        type: MOVE_COMPONENT,
        payload,
    }),
    removeComponent: (payload: number): RemoveComponent => ({
        type: REMOVE_COMPONENT,
        payload,
    }),
    changeIncludeVatRate: (payload: boolean): ChangeIncludeVatRat => ({
        type: CHANGE_INCLUDE_VAT_RAT,
        payload,
    }),
    changeModelOptIn: (payload: boolean): ChangeModelOptIn => ({
        type: CHANGE_MODEL_OPT_IN,
        payload,
    }),
    changeSettings: (payload: AnalyticsSettings): ChangeSettings => ({
        type: CHANGE_SETTINGS,
        payload,
    }),
    changeFilters: (payload: ReportFilterWithOperator[]): ChangeFilters => ({
        type: CHANGE_FILTERS,
        payload,
    }),
};

export default analyticsSettingsReducer;
