import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { push } from 'redux-first-history';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { useModal } from 'platform/common/components/Modal/Modal';
import ObserverStatusIndicator from 'platform/common/components/ObserverStatusIndicator/ObserverStatusIndicator';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_ARCHIVED_LABELS, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { formatDate, formatDateTime, formatHours } from 'platform/common/utils/date.util';
import { makeOptions } from 'platform/common/utils/option.util';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import { toastSuccess } from 'platform/common/utils/toast.util';
import ObserverCreate from './ObserverCreate';
import ObserverDownloadModal from './ObserverDownloadModal';
import ObserverEdit from './ObserverEdit';
import ObserverExecutionAttachmentLink from './ObserverExecutionAttachmentLink';
import {
    OBSERVER_EXECUTION_STATUS_COLORS,
    OBSERVER_EXECUTION_STATUS_NAMES,
    OBSERVER_REPORT_DESTINATION_NAMES,
    OBSERVER_REPORT_MESSAGE_CATEGORIES,
    OBSERVER_REPORT_MESSAGE_TYPES,
    OBSERVER_REPORT_TYPE_NAMES,
} from './observer.constants';
import {
    changeObserverReportStatus,
    createObserverReport,
    executeObserverReport,
    executeObserverReportForMe,
    getObserverExecutions,
    getObserverReport,
    getObserverReports,
} from './observer.service';
import {
    ObserverExecution,
    ObserverExecutionDetail,
    ObserverReportDestination,
    ObserverReportRow,
    ObserverReportType,
} from './observer.types';

export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

const REPORT_RESEND_SUCCESS_MSG = 'Your request processed successfully';

type QueryParams = {
    searchQuery: string;
    status: ActiveOrArchived[];
    destinationFilter: ObserverReportDestination[];
    deeplyEnabled?: boolean;
    messageType: string[];
    messageCategory: string[];
    advertiserIds: number[];
    reportType?: ObserverReportType;
};

const ObserverList = () => {
    const {
        queryParams: {
            status,
            searchQuery,
            deeplyEnabled,
            messageType,
            reportType,
            destinationFilter,
            messageCategory,
            advertiserIds,
        },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        searchQuery: '',
        deeplyEnabled: undefined,
        messageType: [],
        messageCategory: [],
        reportType: undefined,
        destinationFilter: [],
        advertiserIds: [],
    });

    const { showModal } = useModal();
    const dispatch = useDispatch();
    const canEdit = useFeature('ADMIN_EDIT');
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);
    const advertiserName = (id?: number): string => advertiserOptions.find((o) => o.id === id)?.name || '-';

    const [{ data, loading }, refetch] = usePromise(
        [],
        () =>
            getObserverReports({
                status,
                reportType,
                deeplyEnabled,
                messageType,
                messageCategory,
                advertiserIds,
            }),
        [status, reportType, deeplyEnabled, messageType, messageCategory, advertiserIds]
    );

    const getRowActions = (observer: ObserverReportRow) => {
        const items: PopoverDropdownItem[] = [
            {
                label: canEdit ? 'Edit' : 'View',
                action: () => {
                    dispatch(push(`/observer/${observer.id}`));
                },
            },
        ];

        if (observer.destinations?.includes('MESSAGE_CENTER') || observer.destinations?.includes('EMAIL')) {
            items.push({
                label: 'Send email (only me)',
                action: () => {
                    executeObserverReportForMe(observer.id).then(() =>
                        toastSuccess(REPORT_RESEND_SUCCESS_MSG)
                    );
                },
            });
        }

        if (observer.destinations?.includes('EMAIL')) {
            items.push({
                label: 'Send email (all recipients)',
                action: () => {
                    executeObserverReport(observer.id).then(() => toastSuccess(REPORT_RESEND_SUCCESS_MSG));
                },
            });
        } else if (reportType === 'SCHEDULED' || reportType === 'ALERT') {
            items.push({
                label: 'Run now',
                action: () => {
                    executeObserverReport(observer.id).then(() => toastSuccess(REPORT_RESEND_SUCCESS_MSG));
                },
            });
        } else {
            items.push({
                label: 'Download',
                action: () =>
                    showModal((toggle) => <ObserverDownloadModal toggle={toggle} reportId={observer.id} />),
            });
        }

        if (canEdit) {
            items.push(
                {
                    label: 'Duplicate',
                    action: async () => {
                        const report = await getObserverReport(observer.id);
                        await createObserverReport({ ...report, name: `Copy of ${report.name}` });
                        toastSuccess('Report has been duplicated.');
                        refetch();
                    },
                },
                {
                    label: observer.status === 'ARCHIVED' ? 'Activate' : 'Archive',
                    action: async () => {
                        const newStatus = observer.status === 'ARCHIVED' ? 'ACTIVE' : 'ARCHIVED';
                        await changeObserverReportStatus(observer.id, newStatus);
                        refetch();
                    },
                }
            );
        }

        return items;
    };

    const reportColumns: TableColumn<ObserverReportRow>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            width: 70,
            depth: 0,
            Cell: ({ original }) => <StatusBadge status={original.status} />,
        },
        {
            screenOnly: true,
            Header: 'Type',
            depth: 0,
            accessor: 'reportType',
            width: 120,
            Cell: ({ original }) => (
                <>
                    <Badge color="secondary" className="badge-outline-secondary">
                        {OBSERVER_REPORT_TYPE_NAMES[original.reportType]}
                    </Badge>
                    {original.deeplyEnabled && (
                        <Badge color="primary" className="badge-outline ms-1">
                            DeeplyAI
                        </Badge>
                    )}
                </>
            ),
        },
        {
            Header: 'ID / System key',
            accessor: (r) => `${r.id}${r.systemKey ? ` / ${r.systemKey}` : ''}`,
            width: 120,
            depth: 0,
            type: DATA_TYPES.ID,
        },
        {
            Header: 'Name',
            accessor: 'name',
            depth: 0,
            type: DATA_TYPES.TEXT,
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Advertiser',
            accessor: 'name',
            depth: 0,
            width: 180,
            Cell: ({ original }) => advertiserName(original.advertiserId),
        },
        {
            Header: 'Last run',
            accessor: 'lastRun',
            depth: 0,
            width: 90,
            Cell: ({ original }) => (
                <LabelWithSubtext
                    label={formatDate(original.lastRun)}
                    subtext={formatHours(original.lastRun)}
                />
            ),
        },
        {
            screenOnly: true,
            Header: '',
            accessor: 'lastRunStatus',
            depth: 0,
            width: 35,
            sortable: false,
            Cell: ({ original }) => <ObserverStatusIndicator status={original.lastRunStatus} />,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            depth: 0,
            width: 130,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
            exportCell: (row) => `${formatDateTime(row.updatedOn)}/${row.updatedBy}`,
        },
        {
            className: 'pull-right cell-align-right',
            sortable: false,
            depth: 0,
            width: 50,
            Cell: ({ original }) => <InlineDropdown items={getRowActions(original)} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 100,
            depth: 1,
        },
    ];

    const executionColumns: TableColumn<ObserverExecution>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            width: 100,
            Cell: ({ original }) => (
                <Badge className="badge-outline" color={OBSERVER_EXECUTION_STATUS_COLORS[original.status]}>
                    {OBSERVER_EXECUTION_STATUS_NAMES[original.status]}
                </Badge>
            ),
            depth: 1,
        },
        {
            Header: 'Started at',
            accessor: 'startedAt',
            type: DATA_TYPES.DATE_TIME,
            width: 150,
            depth: 1,
        },
        {
            Header: 'Duration',
            accessor: 'duration',
            width: 100,
            Cell: ({ original }) => `${original.duration}s`,
            depth: 1,
        },
        {
            Header: 'Started by',
            accessor: 'startedBy',
            type: DATA_TYPES.TEXT,
            depth: 1,
        },
    ];

    const detailColumns: TableColumn<ObserverExecutionDetail>[] = [
        {
            Header: 'Recipient',
            accessor: 'recipient',
            type: DATA_TYPES.TEXT,
            depth: 2,
        },
        {
            Header: 'Number of entries',
            accessor: 'rows',
            depth: 2,
        },
        {
            Header: 'Mail body',
            accessor: 'mailBody',
            Cell: ({ original: { mailBody } }) => (
                <>
                    {mailBody && (
                        <Tooltip
                            placement="bottom-start"
                            className="d-flex justify-content-center"
                            renderTooltip={() => <div className="display-linebreak">{mailBody}</div>}
                        >
                            <i
                                tabIndex={0}
                                role="button"
                                onClick={() => copyToClipboard(mailBody)}
                                className="fa fa-copy"
                            />
                        </Tooltip>
                    )}
                </>
            ),
            depth: 2,
        },
        {
            Header: 'Attachment',
            accessor: 'hasAttachment',
            Cell: ({ original: { reportId, executionId, id, hasAttachment } }) =>
                hasAttachment ? (
                    <ObserverExecutionAttachmentLink
                        reportId={reportId}
                        executionId={executionId}
                        detailId={id}
                    />
                ) : null,
            depth: 2,
        },
    ];

    const observerReports = filterBySearchQuery(
        destinationFilter.length
            ? data.filter((report) =>
                  report.destinations?.some((destination) => destinationFilter.includes(destination))
              )
            : data,
        [...reportColumns.map((column) => column.accessor), 'updatedUserName'],
        searchQuery
    );

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Observer" />
            </HeaderContainer>
            <BodyContainer helpKey="observer_list">
                {/* prettier-ignore */}
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={makeOptions(ACTIVE_OR_ARCHIVED_LABELS)}
                        onChange={(value) => setQueryParams({ status: value })}
                        isMulti
                    />
                    <SelectWithAddon
                        name="Type"
                        value={reportType}
                        options={makeOptions(OBSERVER_REPORT_TYPE_NAMES)}
                        onChange={(value) => setQueryParams({ reportType: value })}
                    />
                    <SelectWithAddon
                        name="Advertiser"
                        value={advertiserIds}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={advertiserOptions}
                        onChange={(value) => setQueryParams({ advertiserIds: value })}
                        isMulti
                    />
                    <SelectWithAddon
                        name="Delivery"
                        value={destinationFilter}
                        options={makeOptions(OBSERVER_REPORT_DESTINATION_NAMES)}
                        onChange={(value) => setQueryParams({ destinationFilter: value })}
                        isMulti
                    />
                    <SelectWithAddon
                        name="DeeplyAI"
                        value={deeplyEnabled}
                        options={[
                            { label: 'Active', value: true },
                            { label: 'Inactive', value: false },
                        ]}
                        onChange={(value) => setQueryParams({ deeplyEnabled: value })}
                        isClearable
                    />
                    <SelectWithAddon
                        name="Message category"
                        value={messageCategory}
                        options={makeOptions(OBSERVER_REPORT_MESSAGE_CATEGORIES)}
                        onChange={(value) => setQueryParams({ messageCategory: value })}
                        isMulti
                    />
                    <SelectWithAddon
                        name="Message type"
                        value={messageType}
                        options={makeOptions(OBSERVER_REPORT_MESSAGE_TYPES)}
                        onChange={(value) => setQueryParams({ messageType: value })}
                        isMulti
                    />
                </ListFilters>
                <FormattedTable
                    withLoadMore
                    isTree
                    childrenOffset={0}
                    childrenLoaders={[
                        {
                            field: 'executions',
                            lazyLoad: ({ id }, offset?: number) => getObserverExecutions(id, { offset }),
                        },
                        {
                            field: 'details',
                        },
                    ]}
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () => {
                                          dispatch(push(`/observer/new`));
                                      }
                                    : undefined
                            }
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                            value={searchQuery}
                        />
                    }
                    columns={[...reportColumns, ...executionColumns, ...detailColumns]}
                    loading={loading}
                    data={observerReports}
                    defaultSorted={[
                        {
                            orderBy: 'updatedOn',
                            direction: 'DESC',
                        },
                    ]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <ObserverCreate redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
                <Route
                    path=":id"
                    element={<ObserverEdit redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />}
                />
            </Routes>
        </>
    );
};

export default ObserverList;
