import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { Status, STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { formatDateTime } from 'platform/common/utils/date.util';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import InsightCreate from 'platform/insight/InsightForm/InsightCreate';
import InsightEdit from 'platform/insight/InsightForm/InsightEdit';
import { INSIGHTS } from 'platform/insight/insight.navigation';
import {
    changeInsightReportStatus,
    fetchInsightQueries,
    fetchInsightReports,
} from 'platform/insight/insight.service';
import { InsightReport } from 'platform/insight/insight.types';

type QueryParams = {
    status: Status[];
    searchQuery: string;
    advertiserIds: number[];
    queryId: string;
};

const InsightList = () => {
    const {
        queryParams: { status, searchQuery, advertiserIds, queryId },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        searchQuery: '',
        advertiserIds: [],
        queryId: '',
    });

    const dispatch = useDispatch();
    const canEdit = useFeature('ADMIN_EDIT');
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options).map((a) => ({
        label: a.name,
        value: a.id,
    }));
    const advertiserName = (id?: number): string =>
        advertiserOptions.find((o) => o.value === id)?.label || '-';

    const [{ data, loading }, refetch] = usePromise(
        [],
        () => fetchInsightReports({ status, advertiserId: advertiserIds }),
        [status, advertiserIds]
    );

    const [{ data: queries }] = usePromise([], () => fetchInsightQueries(), []);

    const getEditItems = (cell: TableCell<InsightReport>): PopoverDropdownItem[] => {
        const action = async (newStatus: Status) => {
            await changeInsightReportStatus(cell.original.id, newStatus);
            refetch();
        };
        const availableActions: Record<Status, PopoverDropdownItem[]> = {
            ACTIVE: [
                { label: 'Deactivate', action: () => action('INACTIVE') },
                { label: 'Archive', action: () => action('ARCHIVED') },
            ],
            INACTIVE: [
                { label: 'Activate', action: () => action('ACTIVE') },
                { label: 'Archive', action: () => action('ARCHIVED') },
            ],
            ARCHIVED: [{ label: 'Activate', action: () => action('ACTIVE') }],
        };

        return [
            {
                label: canEdit ? 'Edit' : 'View',
                action: () => {
                    dispatch(push(`${INSIGHTS.path}/${cell.original.id}`));
                },
            },
            ...(canEdit ? availableActions[cell.original.status] : []),
        ];
    };

    const columns: TableColumn<InsightReport>[] = [
        {
            Header: 'Status',
            accessor: (insight) => insight.status,
            maxWidth: 75,
            Cell: (row) => <StatusBadge status={row.value} />,
        },
        {
            Header: 'ID',
            accessor: (insight) => insight.id,
            type: DATA_TYPES.ID,
            width: 90,
        },
        {
            Header: 'Name',
            accessor: (insight) => insight.name,
            minWidth: 150,
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Advertisers',
            maxWidth: 150,
            Cell: ({ original }) => advertiserName(original.advertiserId),
        },
        {
            Header: 'Description',
            accessor: (insights) => insights.shortDescription,
            maxWidth: 500,
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Edited',
            accessor: (insights) => insights.updatedOn,
            width: 150,
            Cell: (row) => <UpdatedOn date={row.original.updatedOn} updatedBy={row.original.updatedBy} />,
            exportCell: (row) => `${formatDateTime(row.updatedOn)}/${row.updatedBy}`,
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (row) => <InlineDropdown items={getEditItems(row)} />,
        },
    ];

    const filteredData = () => {
        const columnsAccessors = [...columns.map((column) => column.accessor), 'updatedBy'];

        if (queryId) {
            const foundReport = queries.find((query) => query.queryId?.toString() === queryId)?.reportId;
            if (foundReport) {
                return filterBySearchQuery(data, columnsAccessors, searchQuery).filter(
                    (item) => item.id === foundReport
                );
            }
            return [];
        }

        return filterBySearchQuery(data, columnsAccessors, searchQuery);
    };

    return (
        <div>
            <HeaderContainer>
                <PageHeader title={INSIGHTS.name} />
            </HeaderContainer>
            <BodyContainer helpKey="insight_list">
                {!canEdit && <CanNotEditWarning />}
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={STATUS_OPTIONS}
                        onChange={(value) => setQueryParams({ status: value })}
                        isMulti
                    />
                    <SelectWithAddon
                        name="Advertiser"
                        value={advertiserIds}
                        options={advertiserOptions}
                        onChange={(value) => setQueryParams({ advertiserIds: value })}
                        isMulti
                    />
                    <InputWithAddOn
                        leftAddOn={{ title: 'Query ID' }}
                        value={queryId}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setQueryParams({ queryId: e.target.value })
                        }
                    />
                </ListFilters>
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () => {
                                          dispatch(push(`${INSIGHTS.path}/new`));
                                      }
                                    : undefined
                            }
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                            value={searchQuery}
                        />
                    }
                    columns={columns}
                    loading={loading}
                    data={filteredData()}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <InsightCreate
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                            advertiserOptions={advertiserOptions}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <InsightEdit
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                            advertiserOptions={advertiserOptions}
                        />
                    }
                />
            </Routes>
        </div>
    );
};

export default InsightList;
