import { CSSProperties, ReactNode, useRef } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import { Moment } from 'moment';
import { DatePickerMode } from 'platform/analytics/analytics.types';
import { PopoverPlacement } from 'platform/common/components/Popover/Popover';
import useToggle from 'platform/common/hooks/useToggle';
import {
    DateRangePreset,
    DateRanges,
    DEFAULT_DATE_RANGES,
    formatDateRange,
    momentIfDefined,
} from '../../utils/date.util';
import { AddOn } from '../InputWithAddon/InputWithAddOn';
import DateRangePickerPopover from './DateRangePickerPopover';
import './DateRangePicker.scss';

export interface DateRangePickerProps {
    from: string | undefined;
    to: string | undefined;
    preset?: DateRangePreset | undefined;
    minDate?: string;
    maxDate?: string;
    className?: string;
    label?: string;
    placement?: PopoverPlacement;
    placeholder?: string;
    ranges?: Partial<DateRanges>;
    inputGroupStyle?: CSSProperties;
    inputClassName?: string;
    mode?: DatePickerMode;
    forceRangeSelection?: boolean;
    optionalEndDate?: boolean;
    disabled?: boolean;
    fromDate?: Moment;
    toDate?: Moment;
    allowApplyEmptyValues?: boolean;
    onChange: (from: string | undefined, to: string | undefined, preset: DateRangePreset | undefined) => void;
    onClear?: () => void;
    onBlur?: () => void;
    leftAddOn?: AddOn;
    additionalContent?: ReactNode;
}

const DateRangePicker = ({
    className,
    label,
    placement = 'bottom-start',
    ranges,
    mode = 'DEFAULT',
    placeholder,
    inputGroupStyle,
    inputClassName,
    from,
    to,
    minDate,
    maxDate,
    preset,
    optionalEndDate,
    forceRangeSelection,
    disabled,
    fromDate,
    toDate,
    onChange,
    onClear,
    onBlur,
    leftAddOn,
    allowApplyEmptyValues,
    additionalContent,
}: DateRangePickerProps) => {
    const [popoverOpen, togglePopover] = useToggle(false);

    const momentFrom = momentIfDefined(from);
    const momentTo = momentIfDefined(to);
    const momentMin = momentIfDefined(minDate);
    const momentMax = momentIfDefined(maxDate);
    const resolvedRanges = ranges ?? DEFAULT_DATE_RANGES[mode];

    const ref = useRef<HTMLDivElement>(null);

    const onRangeChange = (
        newFrom: string | undefined,
        newTo: string | undefined,
        newPreset: DateRangePreset | undefined
    ) => {
        onChange(newFrom, newTo, newPreset);
        togglePopover();
    };

    const displayDateRange = () => {
        const rangeText = formatDateRange(momentFrom, momentTo);
        const presetLabel = preset && resolvedRanges?.[preset]?.label;
        return presetLabel ? `${presetLabel} (${rangeText})` : rangeText;
    };

    return (
        <>
            <div
                ref={ref}
                className={classNames('DateRangePicker', { popoverOpen }, className)}
                style={{ boxSizing: 'content-box', width: 'auto' }}
                onClick={() => togglePopover()}
                role="button"
                tabIndex={0}
            >
                <InputGroup
                    className={classNames('DateRangePicker--inputGroup', { disabled })}
                    style={inputGroupStyle}
                >
                    {leftAddOn && (
                        <InputGroupText
                            className={classNames({ 'cursor-pointer': leftAddOn.onClick })}
                            onClick={leftAddOn.onClick}
                        >
                            <i className={leftAddOn.className} />
                            {leftAddOn.title}
                        </InputGroupText>
                    )}
                    <InputGroupText className="input-group-text-icon">
                        {label ?? <i className="far fa-calendar-alt" />}
                    </InputGroupText>
                    <Input
                        type="text"
                        readOnly
                        value={displayDateRange()}
                        placeholder={placeholder}
                        className={inputClassName}
                        onBlur={onBlur}
                    />
                </InputGroup>
            </div>
            {ref.current && popoverOpen && (
                <DateRangePickerPopover
                    allowApplyEmptyValues={allowApplyEmptyValues}
                    fromDate={fromDate}
                    toDate={toDate}
                    target={ref.current}
                    placement={placement}
                    toggle={togglePopover}
                    from={momentFrom}
                    to={momentTo}
                    preset={preset}
                    minDate={momentMin}
                    maxDate={momentMax}
                    ranges={resolvedRanges}
                    mode={mode}
                    onChange={onRangeChange}
                    onClear={onClear}
                    onCancel={togglePopover}
                    optionalEndDate={optionalEndDate}
                    forceRangeSelection={forceRangeSelection}
                    additionalContent={additionalContent}
                />
            )}
        </>
    );
};

export default DateRangePicker;
